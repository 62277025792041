import React, { useState, useEffect, useRef } from 'react';
import qs from 'qs';
import ValidateForm from '../../utitls/form/ValidateConditions'
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { fetchSuggestions, fetchtabledata, fetchtabledataFormBuilder, getCrudFieldList, insertFormBuilderData } from '../../customhook/FetchApi';
import { Editor } from 'primereact/editor';
import { MultiSelect } from "react-multi-select-component";
import { deleteFile, deleteMultipleFile, getSelectOption, handleAddressChange, handleChekboxesChange, handleInputChange, handleMultipleFileChange, handleRadioChange, handleSelectChange, handleSelectMultipleChange, handleSelectMultipleOption, handleTextEditorChange, MultiRangeSliderInput, slideOne, slideTwo } from '../../utitls/formbuilder/FormBuilderFunctions'
import MultiRangeSlider from "multi-range-slider-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
// import { Link } from 'react-router-dom'
import { mappls, mappls_plugin } from "mappls-web-maps";
import axios from "axios";

const map_my_india_api = process.env.REACT_APP_MAP_MY_INDIA_API;




const SERVER_URL = 'https://admissioninnepal.in/'

function FormBuilder() {

  const { crud_id, table_name, table_data_id } = useParams()

  console.log("Hiii")
  console.log(crud_id, table_name, table_data_id )

  const [tableData, setTableData] = useState({});

  const [crudFieldData, setCrudFieldData] = useState([]);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  const [selectedValue, setSelectedValue] = useState();

  const [crudFieldDataStatus, setCrudFieldDataStatus] = useState(false)

  const [formSubmitStatus, setFormSubmitStatus] = useState(0)

  const [tableDataFetchStatus, setTableDataFetchStatus] = useState(0)

  const [tableName, setTableName] = useState("")

  const [itemData, setItemData] = useState({})
  const [suggestions, setSuggestions] = useState({});

  useEffect(() => {

    setTableName(table_name)

  }, [table_name])


  // Fetch formData to autofill 
  useEffect(() => {

    const MyfetchTableData = async () => {
      let localItemData = await fetchtabledataFormBuilder(tableName, table_data_id);

      delete localItemData.visiblefields;
      setItemData(localItemData[0]);

      // Set table data fetch status after fetching the data
      setTableDataFetchStatus(true);
    };

    if (table_data_id !== "null") {
      if (tableName) {
        MyfetchTableData();
      }
    }

    else {
      setTableDataFetchStatus(!tableDataFetchStatus); // Set to true or false based on your logic
    }
  }, [formSubmitStatus, tableName]);


  // Fetch crud_field data
  useEffect(() => {
    getCrudFieldList(setCrudFieldData, setFormData, crud_id, itemData, setCrudFieldDataStatus, table_data_id);
  }, [tableDataFetchStatus, itemData]);



  // funtion for checking validation after form submit
  const checkFormValidation = async () => {

    let errorsArray = []
    let isValidArray = []

    const newErrors = {};

    const promises = crudFieldData.map(async (field) => {

      const { pattern, id } = field;
      let value = formData[field.field_name];



      if (pattern) {

        // check if value is type of array then pass value one by one like multiple file
        if (Array.isArray(value) && value.length != 0) {

          for (const newValue of value) {
            const { isValid, errors } = await ValidateForm(pattern, newValue, formData);
            errorsArray.push(errors);
            isValidArray.push(isValid);

            if (!isValid) {
              newErrors[id] = errors;
            }
          }
        }
        // else if(field.field_name == 'editor'){

        // }

        else {

          // Validate single value
          const { isValid, errors } = await ValidateForm(pattern, value, formData);
          errorsArray.push(errors);
          isValidArray.push(isValid);

          if (!isValid) {
            newErrors[id] = errors;
          }
        }
      }

    });

    await Promise.all(promises);
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  // Handle form submission
  const submitForm = async (e) => {
    e.preventDefault();

    // Perform validation check
    const isValid = await checkFormValidation()

    if (isValid) {

      await insertFormBuilderData(formData, table_name, crudFieldData,table_data_id);
      setFormSubmitStatus(!formSubmitStatus)
    } else {

      console.log("Data not validated")
    }
  };



  // Function to safely parse the JSON string
  const parseOptions = (options) => {

    if (!options) {
      return [];
    }
    try {

      // Remove the leading and trailing quotes
      const cleanedOptions = options.trim().replace(/^"|"$/g, '');

      // Parse the cleaned string into a JSON object
      return JSON.parse(cleanedOptions);
    } catch (error) {
      console.error('Failed to parse options:', error);
      return [];
    }
  };


  const fetchTableRecords = async (tableName, fieldId) => {
    let newTableName = tableName.replace('tbl_', '');

    try {
      const response = await fetch(`https://admissioninnepal.in/api/get-data/${newTableName}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: qs.stringify({ key: '12321' }),
      });

      const data = await response.json();

      setTableData((prevData) => ({ ...prevData, [fieldId]: data }));
    } catch (error) {
      console.error('Error fetching table records:', error);
    }
  };

  // Fetch data for dynamic_select fields
  useEffect(() => {
    crudFieldData
      .filter((field) => field.dynamic_table)
      .forEach((field) => {
        fetchTableRecords(field.dynamic_table, field.id);
      });
  }, [crudFieldData]);


  const filterTableData = (tableData, id, dependency, formData, dynamic_condition) => {

    if (!tableData[id]) return [];

    const options = tableData[id].filter(item => item[dependency] == formData[dynamic_condition]);

    return options;
  };


  useEffect(() => {
    console.log("formData")
    console.log(formData)

  }, [formData])

  useEffect(() => {
    console.log("cru")
    console.log(crudFieldData)
  }, [crudFieldData])


  // Handle address input change
  const handleAddressChange = (e, field_name) => {

    setFormData((prevData) => ({
      ...prevData,
      [field_name]: e.target.value

    }))

    if (formData[field_name].length > 1) {
      console.log("address changed")
      console.log(formData[field_name])

      // Fetch suggestions if query length is greater than 2
      fetchSuggestions(e, setSuggestions, map_my_india_api);
    } else {
      setSuggestions({});
    }

  };


  function setSuggestionsLocation(field_name) {

    setFormData((prevData) => ({
      ...prevData,
      [field_name]: suggestions.formattedAddress

    }))
    setSuggestions({}); // Clear suggestions after selection
  }





  return (
    <>
      <Header />
      <Sidebar />
      <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
            <div
              id="kt_app_toolbar_container"
              className="app-container container-xxl d-flex flex-stack"
            >
              <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-5 text-center">
                  Add Data
                </h1>
                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                  <li className="breadcrumb-item text-muted">
                    <Link
                      to="/dashboardpage"
                      className="text-muted text-hover-primary"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <span className="bullet bg-gray-500 w-5px h-2px" />
                  </li>
                  <li className="breadcrumb-item text-muted">Add Data</li>
                </ul>
              </div>
            </div>
          </div>


          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-xxl"
            >
              <div className="card">
                <div className="card-body p-0">
                  <div className="card-px my-10" id="c">
                    <div className="d-flex flex-column flex-lg-row mb-17">
                      <div className="flex-lg-row-fluid me-0 me-lg-20">


                        <form encType='multipart/form-data'
                        >
                          <div className='row'>
                            {crudFieldData
                              .filter((field) => field.status === '1')
                              .map((field) => {

                                const { field_type, field_name, col_size, id, options, dynamic_table, dependency, dynamic_label, dynamic_field, dynamic_condition, pattern, field_label } = field;

                                const multipleSelectOptions = getSelectOption(id, tableData, dynamic_field, dynamic_label);

                                let optionsArray = [];
                                if (options) {
                                  optionsArray = parseOptions(options);

                                }

                                //  allowedExtension
                                let acceptPattern = ""
                                let patternJson;
                                if (pattern) {
                                  patternJson = JSON.parse(pattern)
                                }

                                let allowedExtension = parseOptions(pattern).allowedExtensions;
                                if (allowedExtension) {  // Check if pattern is not empty

                                  acceptPattern = allowedExtension.split(',').map(ext => `.${ext}`).join(','); // Convert to '.pdf,.jpg'

                                }

                                // const defaultSelectedOption = optionsArray.find((opt) => opt.defaultValue)?.value || "";

                                return (
                                  <div key={id} className={`${col_size} mb-5`}>
                                    <label className="fs-5 fw-semibold mb-2">
                                      {(field_label || field_name).replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())}
                                    </label>


                                    {/* Checkbox and radio inputs */}
                                    {optionsArray.length > 0 ? (
                                      field_type === 'custom_checkbox' ? (
                                        <div>
                                          {optionsArray.map((opt) => (
                                            <div className="form-check" key={opt.id}>
                                              <input
                                                type="checkbox"
                                                name={field_name}
                                                value={opt.value}
                                                checked={formData[field_name]?.includes(opt.value) || false}
                                                className="form-check-input"
                                                onChange={(e) => handleChekboxesChange(e, opt.value, setFormData)}
                                              />
                                              <label htmlFor={`${field_name}_${opt.value}`} className="form-check-label">
                                                {opt.label}
                                              </label>
                                            </div>
                                          ))}
                                        </div>
                                      ) : field_type === 'custom_option' ? (
                                        <div>
                                          {optionsArray.map((opt, i) => (
                                            <div className="form-check" key={i}>
                                              <input
                                                type="radio"
                                                name={field_name}
                                                value={opt.value}
                                                checked={formData[field_name] === opt.value}
                                                className="form-check-input"
                                                onChange={(e) => handleRadioChange(e, setFormData, setSelectedValue)}
                                              />
                                              <label htmlFor={`${field_name}_${opt.value}`} className="form-check-label">
                                                {opt.label}
                                              </label>
                                            </div>
                                          ))}
                                        </div>
                                      ) :

                                        field_type === 'custom_select' ? (
                                          <div>
                                            <div className="form-check">
                                              <select
                                                name={field_name}
                                                value={formData[field_name]}
                                                className="form-select form-select-sm"
                                                onChange={(e) => handleSelectChange(e, setFormData, setSelectedValue)}
                                              >
                                                <option value="">Select option</option>
                                                {optionsArray.map((option, index) => (
                                                  <option key={index} value={option.value}>
                                                    {option.label}
                                                  </option>
                                                ))}
                                              </select>
                                            </div>
                                          </div>
                                        ) :

                                          field_type === 'custom_select_multiple' ? (
                                            <div>

                                              {console.log("hii")}
                                              {console.log(Array.isArray(formData[field_name]))}
                                              {console.log(formData[field_name])}

                                              <div className="form-check">
                                                <MultiSelect
                                                  options={optionsArray}
                                                  // value={
                                                  //   formData[field_name] && Array.isArray(formData[field_name])
                                                  //     ? formData[field_name].map(value => optionsArray.find(option => option.value === value))

                                                  //     :  toArray(formData)
                                                  // }

                                                  // getting formData[field_name] in below formate convert into array
                                                  // male,fe,tran 

                                                  value={
                                                    formData[field_name] && typeof formData[field_name] === 'string'
                                                      ? formData[field_name].split(',').map(value =>
                                                        optionsArray.find(option => option.value === value)
                                                      ).filter(option => option !== undefined) // Ensure valid options
                                                      : Array.isArray(formData[field_name])


                                                        ? formData[field_name].map(value =>
                                                          optionsArray.find(option => option.value === value)
                                                        ).filter(option => option !== undefined)
                                                        : [] // Fallback for an empty or invalid value
                                                  }



                                                  onChange={(selected) => {
                                                    const selectedValues = selected.map(option => option.value);

                                                    setFormData({ ...formData, [field_name]: selectedValues });
                                                  }}
                                                  labelledBy="Select"
                                                />
                                              </div>
                                            </div>
                                          ) : null
                                    ) :

                                      field_type === 'select_multiple' ? (
                                        <div className="form-group">
                                          {tableData && (
                                            <>

                                              {/* <label htmlFor={field_name}>{dynamic_label || field_name}</label> */}
                                              <MultiSelect
                                                options={multipleSelectOptions} // Safely use the options array

                                                value={
                                                  Array.isArray(formData[field_name])
                                                    ? formData[field_name].map(value =>
                                                      multipleSelectOptions.find(option => option.value === value)
                                                    ).filter(option => option !== undefined) // Filter out undefined values
                                                    : []
                                                }


                                                onChange={(selected) => {
                                                  handleSelectMultipleOption(selected, formData, setFormData, field_name);
                                                }}
                                                labelledBy="Select"
                                              />

                                            </>
                                          )}
                                        </div>
                                      ) :

                                        field_type == "timestamp" ? (
                                          <>

                                            <div>

                                              <input
                                                type="datetime-local"
                                                name={field_name}
                                                value={formData[field_name] || ''}  // Display the current value from formData or an empty string
                                                className="form-control"
                                                onChange={(e) => {
                                                  setFormData(prevState => ({
                                                    ...prevState,
                                                    [field_name]: e.target.value  // Update the timestamp in formData
                                                  }));

                                                }}
                                              />
                                            </div>
                                          </>
                                        ) :

                                          field_type === 'dynamic_select' ? (
                                            <div className="form-group">
                                              {/* <label htmlFor={field_name}>{dynamic_label || field_name}</label> */}

                                              <select
                                                name={field_name}
                                                value={formData[field_name] || ''}
                                                className="form-select"
                                                onChange={(e) => handleSelectChange(e, setFormData, setSelectedValue)}
                                              >
                                                <option value="">Select an option</option>
                                                {tableData[id]?.map((item) => (
                                                  <option key={item["id"]} value={item[dynamic_field]}>
                                                    {item[dynamic_label]} {/* Accessing the dynamic label */}
                                                  </option>
                                                ))}
                                              </select>
                                            </div>
                                          ) :


                                            field_type === 'chained' ? (

                                              // {filterTableData(tableData, id, dependency, formData, dynamic_condition)}
                                              <div className="form-group">
                                                <select
                                                  name={field_name}
                                                  value={formData[field_name] || ''}
                                                  className="form-select"
                                                  onChange={(e) => handleSelectChange(e, setFormData, setSelectedValue)}
                                                >

                                                  <option value="">Select an option</option>
                                                  {filterTableData(tableData, id, dependency, formData, dynamic_condition).map(filteredItem => (
                                                    <option key={filteredItem["id"]} value={filteredItem[dynamic_field]}>
                                                      {filteredItem[dynamic_label]}
                                                    </option>
                                                  ))}

                                                </select>
                                              </div>
                                            ) :


                                              field_type === 'select' ? (
                                                <div className="form-group">
                                                  <select
                                                    name={field_name}
                                                    value={formData[field_name] || []}
                                                    className="form-select"
                                                    onChange={(e) => handleSelectChange(e, dynamic_table, id)}
                                                  >
                                                    <option value="">Select an option</option>

                                                    {tableData[id]?.map((item) => (
                                                      <option key={item["id"]} value={item[dynamic_field]}>
                                                        {item[dynamic_label]} {/* Accessing the dynamic label */}
                                                      </option>
                                                    ))}
                                                  </select>

                                                </div>
                                              ) :

                                                field_type === 'checkboxes' ? (
                                                  <div className="form-group" >

                                                    {tableData[id]?.map((item, index) => (
                                                      <div key={index}>
                                                        <input
                                                          type="checkbox"
                                                          id={`${field_name}_${index}`}  // Unique id for each checkbox                                                        className="form-check-input"
                                                          name={field_name}

                                                          checked={
                                                            Array.isArray(formData[field_name]) && formData[field_name].includes(item[dynamic_field].toString())  // Check if it's an array and if the value is in the array

                                                            // Array.isArray(formData[field_name]) ? formData[field_name] : []
                                                          }
                                                          onChange={(e) => handleChekboxesChange(e, item[dynamic_field], setFormData)}
                                                        />

                                                        <label htmlFor="" className="form-check-label">{item[dynamic_label]}</label>
                                                      </div>
                                                    ))}

                                                  </div>
                                                )

                                                  // check formData[field_name] is array then array contains id for multiple checkbox to auto checked else put ""


                                                  : field_type === "password" ? (
                                                    <input
                                                      id={field_name}
                                                      type="password"
                                                      value={formData[field_name] || ""}
                                                      className="form-control"
                                                      name={field_name}
                                                      onChange={(e) => handleInputChange(e, setFormData)}
                                                    />
                                                  ) :

                                                    //range input type

                                                    field_type === "range" ? (
                                                      <div>
                                                        {/* Parse the pattern only once for efficiency */}
                                                        <input
                                                          id={field_name}
                                                          type="range"
                                                          min={parseInt(patternJson.min)}
                                                          max={parseInt(patternJson.max)}
                                                          // value={(patternJson.min + patternJson.max) / 2} 
                                                          value={formData[field_name] || (parseInt(patternJson.min) + parseInt(patternJson.max)) / 2}
                                                          className="w-100"
                                                          name={field_name}
                                                          onChange={(e) => handleInputChange(e, setFormData)}
                                                        />

                                                        <input
                                                          type="number"
                                                          className='form-control'

                                                          value={formData[field_name] || (parseInt(patternJson.min) + parseInt(patternJson.max)) / 2} // Reflect the same range value in this input
                                                          readOnly // This makes the input non-editable, reflecting only the slider value
                                                        />
                                                      </div>
                                                    ) :



                                                      // double pointer range
                                                      field_type === "range_double_slider" ? (
                                                        <div>

                                                          <MultiRangeSlider
                                                            min={parseInt(patternJson.min)}
                                                            max={parseInt(patternJson.max)}
                                                            step={5}
                                                            minValue={formData[field_name]?.[0] || parseInt(patternJson.min)}
                                                            maxValue={formData[field_name]?.[1] || parseInt(patternJson.max)}
                                                            onInput={(e) => {
                                                              MultiRangeSliderInput(e, setFormData, field_name);
                                                            }}
                                                          />
                                                        </div>
                                                      )


                                                        : field_type === "textarea" ? (
                                                          <textarea
                                                            id={field_name}
                                                            value={formData[field_name]}
                                                            className="form-control"
                                                            name={field_name}
                                                            onChange={(e) => handleInputChange(e, setFormData)}
                                                          />
                                                        )

                                                          : field_type === "text" ? (
                                                            <input
                                                              id={`input-${field.id}`}
                                                              type="text"
                                                              value={formData[field_name] || ""}
                                                              className="form-control"
                                                              name={field_name}
                                                              onChange={(e) => handleInputChange(e, setFormData)}
                                                            />

                                                            //input type number
                                                          ) : field_type === "number" ? (
                                                            <input
                                                              id={`input-${field.id}`}
                                                              type="text"
                                                              value={formData[field_name] || ""}
                                                              className="form-control"
                                                              name={field_name}
                                                              onChange={(e) => handleInputChange(e, setFormData)}
                                                            />
                                                          )


                                                            : field_type === "current_user_id" ? (
                                                              <input
                                                                id={`input-${field.id}`}
                                                                value={localStorage.getItem('token')}
                                                                type="text"
                                                                className="form-control"
                                                                name={field_name}
                                                                onChange={(e) => handleInputChange(e, setFormData)}
                                                              />
                                                            ) :

                                                              field_type === "editor" ? (
                                                                <div>

                                                                  <Editor

                                                                    value={formData[field_name] || ""}

                                                                    name={field_name}
                                                                    onTextChange={(e) => handleTextEditorChange(e, field_name, setFormData)}

                                                                    style={{ height: '320px' }}
                                                                  />
                                                                </div>

                                                                // formData object given below. field_name = editor. i want to access htmlValue but geting undefined.


                                                              )
                                                                : field_type === "yesno" ? (
                                                                  <div>

                                                                    <input
                                                                      type="radio"
                                                                      name={field_name}
                                                                      value={1}
                                                                      // Preselect the "Yes" option if formData[field_name] is 1
                                                                      checked={parseInt(formData[field_name]) === 1}

                                                                      className="form-check-input"
                                                                      onChange={(e) => handleInputChange(e, setFormData)}
                                                                    />


                                                                    <label htmlFor={field_name + "_yes"}>Yes</label>

                                                                    <input
                                                                      type="radio"
                                                                      name={field_name}
                                                                      value={0}
                                                                      // Preselect the "No" option if formData[field_name] is 0
                                                                      checked={parseInt(formData[field_name]) === 0}

                                                                      className="form-check-input"

                                                                      onChange={(e) => handleInputChange(e, setFormData)}
                                                                    />
                                                                    <label htmlFor={field_name + "_no"}>No</label>

                                                                  </div>
                                                                )





                                                                  : field_type === "file" ? (
                                                                    <div>
                                                                      <input
                                                                        id={field_name}
                                                                        type="file"
                                                                        accept={acceptPattern || "*"}  // Use the dynamically created pattern or "*" if empty
                                                                        className="form-control"
                                                                        name={field_name}
                                                                        onChange={(e) => handleInputChange(e, setFormData)}
                                                                      />

                                                                      {(formData[field_name] && !(formData[field_name] instanceof File)) && (
                                                                        <>
                                                                          <Link
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            to={SERVER_URL + formData[field_name]}>Open File

                                                                          </Link>
                                                                          <FontAwesomeIcon
                                                                            className='btn border border-none fs-3 ms-3'
                                                                            onClick={(e) => deleteFile(e, setFormData, field_name)}
                                                                            icon={faXmark}
                                                                          />

                                                                        </>
                                                                      )}

                                                                    </div>

                                                                  )

                                                                    // Warning: Functions are not valid as a React child. This may happen if you return a Component instead of <Component /> from render. Or maybe you meant to call this function rather than return it.
                                                                    : field_type === "filemultiple" ? (
                                                                      <>
                                                                        <input
                                                                          type="file"
                                                                          multiple
                                                                          accept={acceptPattern || "*"}
                                                                          className="form-control"
                                                                          name={field_name}
                                                                          onChange={(e) => handleMultipleFileChange(e, setFormData)}
                                                                        />

                                                                        {console.log(formData[field_name])}

                                                                        {formData[field_name] && (
                                                                          <>
                                                                            {
                                                                              (() => {
                                                                                let multipleFileCheck = Array.isArray(formData[field_name])
                                                                                  ? formData[field_name]
                                                                                  : formData[field_name]?.split(',') || [];

                                                                                console.log("multipleFileCheck")
                                                                                console.log(multipleFileCheck)

                                                                                let containsNonFile = true;

                                                                                if (Array.isArray(formData[field_name])) {
                                                                                  containsNonFile = multipleFileCheck.some(file => !(file instanceof File));

                                                                                } else {
                                                                                  containsNonFile = true
                                                                                }

                                                                                if (containsNonFile) {
                                                                                  return multipleFileCheck.map((file, index) => (
                                                                                    <div key={index}>

                                                                                      <Link
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer"
                                                                                        to={SERVER_URL + file}
                                                                                      >
                                                                                        Open File {index + 1}
                                                                                      </Link>

                                                                                      <FontAwesomeIcon
                                                                                        className="btn border border-none fs-3 ms-3"
                                                                                        onClick={(e) => deleteMultipleFile(e, setFormData, field_name, file, index)}
                                                                                        icon={faXmark}
                                                                                      />

                                                                                    </div>
                                                                                  ));
                                                                                }
                                                                              })()


                                                                            }
                                                                          </>
                                                                        )}
                                                                      </>
                                                                    )


                                                                      : field_type === "address_suggession" ? (
                                                                        <div style={{ width: "100%", height: "99vh", display: "inline-block" }}>
                                                                          <input
                                                                            type="text"
                                                                            className='address_input form-control'
                                                                            // className="",
                                                                            value={formData[field_name] ? formData[field_name] : ""}

                                                                            onChange={(e) => handleAddressChange(e, field_name)}
                                                                            placeholder="Enter address"

                                                                          />

                                                                          {/* Render suggestions if available */}
                                                                          {Object.keys(suggestions).length > 0 && (
                                                                            <ul className="address_suggestions_ul"   >
                                                                              {/* Map over the suggestions and render list items */}
                                                                              <li
                                                                                onClick={() => { setSuggestionsLocation(field_name) }}
                                                                                style={{ cursor: "pointer" }}
                                                                              >
                                                                                {suggestions.formattedAddress}

                                                                              </li>
                                                                            </ul>
                                                                          )}

                                                                        </div>

                                                                      )

                                                                        : field_type === "addressmap" ? (
                                                                          <iframe
                                                                            src={`https://maps.google.com/maps?q=${encodeURIComponent(
                                                                              field.address
                                                                            )}&output=embed`}
                                                                            width="600"
                                                                            height="450"
                                                                            frameBorder="0"
                                                                            style={{ border: 0 }}
                                                                            allowFullScreen=""
                                                                            aria-hidden="false"
                                                                            tabIndex="0"
                                                                            title="Address Map"
                                                                          ></iframe>
                                                                        ) : field_type === "truefalse" ? (
                                                                          <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            name={field_name}

                                                                            checked={parseInt(formData[field_name]) === 1}

                                                                            // checked = {formData[field_name] === 1}
                                                                            onChange={(e) => handleInputChange(e, setFormData)}
                                                                          />
                                                                        )
                                                                          : field_type === "datetime" ? (
                                                                            <input
                                                                              type="datetime-local"
                                                                              className="form-control"
                                                                              name={field_name}
                                                                              value={formData[field_name]}
                                                                              onChange={(e) => handleInputChange(e, setFormData)}
                                                                            />
                                                                          ) : field_type === "date" ? (
                                                                            <input
                                                                              type="date"
                                                                              className="form-control"
                                                                              name={field_name}
                                                                              value={formData[field_name]}
                                                                              onChange={(e) => handleInputChange(e, setFormData)}
                                                                            />
                                                                          ) : field_type === "year" ? (
                                                                            <select
                                                                              className="form-control"
                                                                              name={field_name}
                                                                              value={formData[field_name]}
                                                                              onChange={(e) => handleInputChange(e, setFormData)}
                                                                            >
                                                                              <option value="">Select Year</option>
                                                                              {Array.from({ length: 201 }, (_, index) => {
                                                                                const year = 1900 + index;
                                                                                return (
                                                                                  <option key={year} value={year}>
                                                                                    {year}
                                                                                  </option>
                                                                                );
                                                                              })}
                                                                            </select>
                                                                          )
                                                                            : field_type === "time" ? (
                                                                              <input
                                                                                type="time"
                                                                                className="form-control"
                                                                                value={formData[field_name]}
                                                                                name={field_name}
                                                                                onChange={(e) => handleInputChange(e, setFormData)}
                                                                              />
                                                                            ) :
                                                                              null}

                                    <span className="error" style={{ color: 'red' }}>
                                      {Array.isArray(errors[id])
                                        ? errors[id].map((err, idx) => <div key={idx}>{err}</div>)
                                        : errors[id] || ''}
                                    </span>
                                    <br />
                                  </div>
                                );
                              })}

                            <button className='btn btn-primary w-100' type="submit" onClick={(e) => submitForm(e)}>Submit</button>
                          </div>
                        </form>


                        <ToastContainer />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    </>
  );
}

export default FormBuilder;



