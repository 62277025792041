

import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const CardPopUp = () => {

    const [formDisplayStatus, setFormDisplayStatus] = useState(false)

    function openModel() {

        setFormDisplayStatus(!formDisplayStatus)
    }


    return (
        <div className='container'>
            <div className="row">
                <div className="col">

                    {/* form rotate when click on button */}

                    <div className={`formRotate ${formDisplayStatus ? 'show' : 'hide'}`}>
                        <Form  >
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" />

                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Password" />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Check me out" />
                            </Form.Group>

                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                        </Form>
                    </div>


                    <Button className="my-4" variant="primary" type="submit" onClick={() => openModel()}>
                        Opend Model
                    </Button>

                </div>
            </div>

        </div>
    )
}

export default CardPopUp

// want to convert d-none to d-block when click on open model button