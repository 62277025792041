import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const CreateUser = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    password: "",
    userType: "",
    location: "",
    user_id: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Map user types to their respective values
    const userTypeMap = {
      Admin: "1",
      Student: "2",
      College: "3",
      Teacher: "4",
      User: "5",
    };
    const userId = localStorage.getItem("token"); // Assuming token is user_id
    console.log("userid")
    console.log(userId)



    const dataToSubmit = {
      key: "12321", // replace with actual key
      users_name: formData.name,
      email: formData.email,
      contact: formData.number,
      location: formData.location,
      password: formData.password,
      id: userId,
      // select_course: formData.userType,
      user_type: userTypeMap[formData.userType] || "",
      // Add other parameters as needed
    };

    try {
      const response = await axios.post(
        "https://admissioninnepal.in/api/add-edit-users",
        qs.stringify(dataToSubmit),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      console.log("User created successfully:", response.data);
      toast.success("Profile created successfully!");
      resetForm();
    } catch (error) {
      toast.error("Error creating profile: " + error.message);
    }
  };

  // Log formData changes to the console
  useEffect(() => {
    console.log("Current form data:", formData);
  }, [formData]);

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      number: "",
      password: "",
      userType: "",
      location: "",
    });
  };
  return (
    <>
      <ToastContainer />

      <div className="d-flex flex-column flex-root" id="kt_app_root">
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
            <div className="pd-flex flex-center flex-column flex-lg-row-fluid">
              <div className="w-lg-500px p-3 p-md-10">
                <form
                  className="form w-100"
                  noValidate="novalidate"
                  id="kt_sign_up_form"
                  onSubmit={handleSubmit}
                  //   data-kt-redirect-url="authentication/layouts/corporate/sign-in.html"
                  //   action="#"
                >
                  {/*begin::Heading*/}
                  <div className="text-center mb-11">
                    <h1 className="text-gray-900 fw-bolder mb-3">
                      Create Profile{" "}
                    </h1>
                  </div>

                  <div className="row">
                    <div className="fv-row mb-8 col-md-6">
                      <input
                        type="text"
                        placeholder="Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="form-control bg-transparent"
                      />
                    </div>

                    <div className="fv-row mb-8 col-md-6">
                      <input
                        type="text"
                        placeholder="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="form-control bg-transparent"
                      />
                    </div>

                    <div className="fv-row mb-8 col-md-6">
                      <div className="position-relative mb-3">
                        <input
                          className="form-control bg-transparent"
                          type="number"
                          placeholder="Number"
                          name="number"
                          value={formData.number}
                          onChange={handleChange}
                          maxLength={10} // Limit to 10 characters
                          autoComplete="off"
                        />
                      </div>
                    </div>

                    <div className="fv-row mb-8 col-md-6">
                      <input
                        placeholder="Password"
                        name="password"
                        type="password"
                        value={formData.password}
                        onChange={handleChange}
                        autoComplete="off"
                        className="form-control bg-transparent"
                      />
                    </div>

                    <div className="fv-row mb-8 col-md-6">
                      <select
                        name="userType"
                        value={formData.userType}
                        onChange={handleChange}
                        data-control="select2"
                        data-placeholder="Select position..."
                        className="form-select bg-transparent"
                      >
                        <option value="">Select User Type</option>
                        <option value="Admin">Admin</option>
                        <option value="Student">Student</option>
                        <option value="College">College</option>
                        <option value="Teacher">Teacher</option>
                        <option value="User">User</option>
                      </select>
                    </div>

                    <div className="fv-row mb-8 col-md-6">
                      <input
                        type="text"
                        placeholder="Location..."
                        name="location"
                        value={formData.location}
                        onChange={handleChange}
                        autoComplete="off"
                        className="form-control bg-transparent"
                      />
                    </div>
                  </div>

                  <div className="d-grid mb-10">
                    <button
                      type="submit"
                      // id="kt_sign_up_submit"
                      className="btn btn-primary"
                    >
                      <span className="indicator-label">Create Profile</span>
                      <span className="indicator-progress">
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                      </span>
                    </button>
                  </div>

                  <div className="text-gray-500 text-center fw-semibold fs-6">
                    Already have an Account?
                    <Link to="/" className="link-primary fw-semibold mx-2">
                      Login
                    </Link>
                  </div>
                </form>

                {/*end::Form*/}
              </div>
              {/*end::Wrapper*/}
            </div>
          </div>
          {/*end::Body*/}
          {/*begin::Aside*/}
          <div
            className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2"
            style={{ backgroundImage: "url(assets/media/misc/auth-bg.png)" }}
          >
            {/*begin::Content*/}
            <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
              {/*begin::Logo*/}
              <a href="index.html" className="mb-0 mb-lg-12">
                <img
                  alt="Logo"
                  src="assets/media/logos/custom-1.png"
                  className="h-60px h-lg-75px"
                />
              </a>
              {/*end::Logo*/}
              {/*begin::Image*/}
              <img
                className="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20"
                src="assets/media/misc/auth-screens.png"
                alt=""
              />
              {/*end::Image*/}
              {/*begin::Title*/}
              <h1 className="d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7">
                Fast, Efficient and Productive
              </h1>
              {/*end::Title*/}
              {/*begin::Text*/}
              <div className="d-none d-lg-block text-white fs-base text-center">
                In this kind of post,
                <a
                  href="#"
                  className="opacity-75-hover text-warning fw-bold me-1"
                >
                  the blogger
                </a>
                introduces a person they’ve interviewed
                <br />
                and provides some background information about
                <a
                  href="#"
                  className="opacity-75-hover text-warning fw-bold me-1"
                >
                  the interviewee
                </a>
                and their
                <br />
                work following this is a transcript of the interview.
              </div>
              {/*end::Text*/}
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Aside*/}
        </div>
        {/*end::Authentication - Sign-up*/}
      </div>
    </>
  );
};

export default CreateUser;
