// src/utils/apiUtils.js
import Swal from 'sweetalert2';
import qs, { stringify } from "qs";
import { logDOM } from '@testing-library/react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ErrorPage from '../ErrorPage';



const keyvalue = "12321";
const BASE_URL = "https://admissioninnepal.in/api";
const userId = localStorage.getItem('token');


export const fetchUserTableData = async () => {
  try {
    const response = await fetch("https://admissioninnepal.in/Apis/log/loginList", {
      headers: {
        "Content-Type": "Application/json",
      },

    })

    if (response.ok) {
      const data = await response.json();
      return data.data;
    } else {
      throw new Error("Failed to fetch user data");
    }
  } catch (error) {
    console.log(error)

  }
}

export const fetchSuggestions = async (e, setSuggestions, map_my_india_api) => {
  const searchedData = e.target.value;
  try {
    const response = await axios.get(
      `https://admissioninnepal.in/api/map_data/${searchedData}`,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",

          "Authorization": `Bearer ${map_my_india_api}`,
        },

      }
    );

    console.log(response.data)

    if (response.data.copResults) {
      setSuggestions(response.data.copResults); // Set suggestions from the response
    } else {
      setSuggestions({}); // If no results, clear suggestions
    }
  } catch (error) {
    console.error("Error fetching address suggestions:", error);
  }
};


export const handleDeleteGroup = async (id, table_name) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "No, cancel!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        const data = qs.stringify({
          id,
          key: 12321,
          table: table_name,
        });

        await axios.post(
          // "https://admissioninnepal.in/api/delete-group",
          `https://admissioninnepal.in/api/delete_table_data_permanently/${table_name}`,


          data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "Authorization": `Bearer 12321`
            },
          }
        );

        // Success Swal alert and page reload
        Swal.fire({
          title: "Deleted!",
          text: "Item has been deleted.",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          // Reload the page when the user presses OK
          window.location.reload();
        });
      } catch (error) {
        console.error("Error deleting item:", error);
        Swal.fire({
          title: "Error",
          text: "Failed to delete item",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  });
};


// reload page when api call successfull when use press ok in swal fire


// fetching crud and crudField Data


// correct below code structure to call api after getting response from
export const fetchCrudAndCrudField = async (
  id,
  setCrudTableData,
  setCrudFieldData,
  setIsTableVisible,
  setTableFields,
  setCrudDataVisibility,
  setSelectedSuperParent
) => {

  const newObj = {
    key: "12321",
    id: id
  };

  try {
    // Fetch crud and crud_field data
    const crudFieldResponse = await fetch(`https://admissioninnepal.in/Apis/Crud_field/crudnfield`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: qs.stringify(newObj)
    });

    if (!crudFieldResponse.ok) {
      throw new Error('Failed to fetch crud field data');
    }

    const crudFieldData = await crudFieldResponse.json();

    const crudData = crudFieldData.data['crud'];

    const crud_id = crudData.id;

    const crudField = crudFieldData.data['crud_field'];

    const tableName = crudData['table_name'];




    //fetch columnn from real table which is added after crud completed. to update in crudField

    const tableColumnResponse = await fetch(`https://admissioninnepal.in/api/get-column/${tableName.replace("tbl_", "")}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: qs.stringify(newObj)
    });


    if (!tableColumnResponse.ok) {
      throw new Error('Failed to fetch table columns');
    }

    const tableColumnsData = await tableColumnResponse.json();

    setIsTableVisible(true);

    // Convert pattern and options to objects in crudField
    const processedCrudField = crudField.map(field => {
      return {
        ...field,
        pattern: field.pattern ? JSON.parse(field.pattern) : "",
        options: field.options ? JSON.parse(field.options) : ""
      };
    });



    // get total number of rows in crud_field table to give id to new column added after crud completed.
    // get total number of rows in crud_field table to give id to new column added after crud completed.
    let crud_field_count_response = await fetch(`https://admissioninnepal.in/api/get_count/tbl_crud_field`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: qs.stringify({ key: "12321" })
    });

    let crud_field_count = 0

    if (crud_field_count_response.ok) {
      // Parse the response data as JSON
      crud_field_count = await crud_field_count_response.json();

    } else {
      console.error("Failed to fetch the count data:", crud_field_count_response.statusText);
    }


    if (!crud_field_count_response.ok) {
      throw new Error("crud_field_count error");
    }


    // Append missing columns to crudField
    tableColumnsData.forEach(column => {

      crud_field_count = crud_field_count + 1

      const existsInCrudField = processedCrudField.some(field => field.field_name === column.COLUMN_NAME);

      if (!existsInCrudField) {
        const newField = {
          id: crud_field_count,
          crud_id: crud_id,
          field_name: column.COLUMN_NAME,
          field_label: "",
          field_type: "",
          attribute: "",
          is_required: 0,
          is_dependent: 0,
          dependency: "",
          default_value: "",
          is_dynamic: 0,
          dynamic_table: "",
          dynamic_field: "",
          dynamic_label: "",
          dynamic_condition: "",
          pattern: "",
          status: 0,
          is_visible: 0,
          col_size: "",
          position: "",
          options: ""
        };
        processedCrudField.push(newField);
      }
    });


    // Remove id from crudData and set the rest in state
    const { id: crudId, ...filteredCrudData } = crudData;

    console.log("filteredCrudData")
    console.log(filteredCrudData)


    setSelectedSuperParent(filteredCrudData.group_id)

    setCrudTableData(filteredCrudData);

    setTableFields(crudFieldData.data['table_column']);
    setCrudFieldData(processedCrudField);

    setCrudDataVisibility(true);

  } catch (err) {
    console.error("Error fetching data:", err);
  }
};


export const fetchCrudData = async (id, setCrudTableData) => {

  const newObj = {
    key: "12321",
    id: id
  }

  try {
    const response = await fetch(`${BASE_URL}/crud-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: qs.stringify(newObj)

    })



    if (response.ok) {
      const data = await response.json();


      setCrudTableData(data.data[0])
    }

  } catch (err) {

  }
}


export const submitCrud = async (e, crudTableData) => {
  e.preventDefault();

  const newObj = {
    ...crudTableData,
    key: "12321",
  };

  try {
    const response = await fetch(
      "https://admissioninnepal.in/api/add-edit-crud",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: qs.stringify(newObj),
      }
    );

    if (response.ok) {
      const data = await response.json();
      const crudId = data.data.id;

    }
  } catch (err) {

  }
}

export const getCrudWithCrudFieldDataBySlug = async (slug, setCrudFieldData, setFormData, setCrudTableData) => {
  try {
    const response = await fetch(`${BASE_URL}/crud-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qs.stringify({ key: 12321, slug: slug }), // Correctly encoded body
    });

    if (response.ok) {
      let crudData = await response.json();
      crudData = crudData.data[0]




      const newForm = {
        key: 12321,
        crud_id: crudData.id,
      };

      try {
        const response = await fetch(`${BASE_URL}/crudfield-list`, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: qs.stringify(newForm),
        });

        if (response.ok) {
          const data = await response.json();


          const formBuilderData = data.data;

          if (!formBuilderData || formBuilderData.length === 0) {
            console.warn("No form builder data returned from the API.");
            return;
          }

          // Initialize formData state based on fetched fields
          const initialFormData = formBuilderData.reduce((acc, field) => {

            if (field.field_type === 'custom_checkbox' || field.field_type === 'custom_select_multiple') {
              acc[field.field_name] = []; // Initialize checkboxes as an array
            }
            else if (field.field_type === 'current_user_id') {
              acc[field.field_name] = localStorage.getItem('token');

            } else {
              acc[field.field_name] = ""; // Initialize other fields as an empty string
            }

            // Handle fields with options
            if (field.options && ['custom_select', 'custom_option', 'custom_checkbox', 'custom_select_multiple'].includes(field.field_type)) {
              try {
                const options = JSON.parse(field.options);

                if (field.field_type === 'custom_select_multiple') {
                  const defaultOptions = options
                    .filter(option => option.defaultValue === true)
                    .map(option => option.value);
                  acc[field.field_name] = defaultOptions; // Use this array for preselected values
                }

                // Handle custom_select and custom_option by setting a single default value
                if (['custom_select', 'custom_option'].includes(field.field_type)) {
                  const defaultOption = options.find(option => option.defaultValue === true);
                  if (defaultOption) {
                    acc[field.field_name] = defaultOption.value;
                  }
                }

                // Handle custom_checkbox by appending default values to the array
                if (field.field_type === 'custom_checkbox') {
                  const defaultOptions = options
                    .filter(option => option.defaultValue === true)
                    .map(option => option.value);
                  acc[field.field_name] = defaultOptions;
                }

              } catch (error) {
                console.error(`Error parsing options for field ${field.field_name}:`, error);
              }
            }

            return acc;
          }, {});





          setCrudTableData(crudData)
          setCrudFieldData(formBuilderData);
          setFormData(initialFormData);
        }

      } catch (error) {
        console.error("Fetching sidebar data failed", error);
      }

    }

  } catch (error) {
    console.error("Fetching sidebar data failed", error);
  }

}

// fetch sidebar data

// export const fetchSidebarData = async () => {
//   try {
//     const response = await fetch("https://admissioninnepal.in/Apis/Dashboard", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/x-www-form-urlencoded",
//       },
//       body: qs.stringify({ key: 12321 }), // Correctly encoded body
//     });

//     if (!response.ok) {
//       console.error("Fetching sidebar data failed");
//     }

//     // Parse response as JSON
//     const data = await response.json();

//     // Update sidebar data state
//     // setSideBarData(data);

//     return data
//   } catch (error) {
//     console.error("Fetching sidebar data failed", error);
//   }
// };


export const fetchSidebarData = async () => {



  try {
    const response = await fetch(
      "https://admissioninnepal.in/Apis/Dashboard",
      {
        method: "POST",
      }
    );

    if (!response.ok) {

      <ErrorPage />

    }

    const data = await response.json();

    return (data || []);
  } catch (error) {
    <ErrorPage />
  }
};



// get timestamp formate
export function formatTimestamp() {
  const now = new Date();

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  const milliseconds = String(now.getMilliseconds()).padStart(5, '0'); // Pad to 5 digits for precision
  console.log("date_time")
  console.log(`${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`)

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
}


// why newFormData is empty while controle goes inside    if (value instanceof File) becuse uploading single file when printing key value getring below output


export const insertFormBuilderData = async (formData, table_name, crudFieldData, table_data_id) => {

  let newFormData = new FormData();

  crudFieldData.map((field) => {

    if (field.field_type == 'editor') {

      console.log("editor")
      // Check if the field has a value, if yes, stringify it, otherwise set it to an empty string
      formData[field.field_name] = formData[field.field_name]
        ? JSON.stringify(formData[field.field_name])
        : "";
    }
  })


  // Create an array to hold the data
  const data = [];

  // Populate the data array with form data fields
  for (let [key, value] of Object.entries(formData)) {

    // Handle single file uploads
    if (value instanceof File) {
      // Append the single file to newFormData
      newFormData.append(key, value);

    }

    // Handle multiple file uploads
    else if (Array.isArray(value) && value.length > 1 && value[0] instanceof File) {

      const filesArray = [];
      value.forEach((file, index) => {
        filesArray.push(file);
      });

      // After appending all files, append the final array to newFormData
      filesArray.forEach((file, index) => {
        newFormData.append(`${key}[${index}]`, file);
      });

    }

    // Append other fields to the newFormData
    else {
      // add created at time if data insert

      if (formData.id == "" && key == 'create_at') {

        value = formatTimestamp()

      }


      //add created at when edited.
      if (formData.id != "" && key == 'updated_at') {
        value = formatTimestamp()
      }

      if (formData.id != "" && key == 'updated_by') {

        value = localStorage.getItem('token')

      }

      newFormData.append(`data[${key}]`, value);
    }
  }

  console.log("table_data_id")
  console.log(table_data_id)
  // check if table_data_id exist then update else insert
  if (table_data_id != 'null') {
    newFormData.append('data[id]', table_data_id);
  }


  newFormData.append("table_name", table_name);
  newFormData.append("key", "12321");


  try {
    // Log all entries in newFormData
    const response = await fetch(`${BASE_URL}/insert`, {
      method: "POST",
      body: newFormData, // No need to set Content-Type, fetch will handle it automatically

    });



    const data = await response.json();

    if (response.ok) {
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: data.message || 'Data submitted successfully!',
      }).then(() => {
        window.location.reload()
      })
    }

    else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: data.message || 'Failed to submit data!',
      });
    }
  } catch (error) {
    console.error("Failed to fetch CRUD table data:", error);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'An error occurred while submitting the data!',
    });
    throw error;
  }
}




// update form builder data
export const updateFormBuilderData = async (formData, table_name, id) => {
  const newFormData = new FormData();


  // Populate the FormData with form data fields
  for (const [key, value] of Object.entries(formData)) {

    // Handle single file uploads
    if (value instanceof File) {

      newFormData.append(key, value);
    }
    else if (Array.isArray(value) && value.length > 1 && value[0] instanceof File) {

      value.forEach((file, index) => {




        newFormData.append(`${key}[${index}]`, file);

      });

    } else {

      newFormData.append(`data[${key}]`, value);
    }
  }

  // Append additional fields like table name and key
  newFormData.append("table_name", table_name);
  newFormData.append("key", "12321");

  // Append the id for the update
  newFormData.append("data[id]", id);


  for (let pair of newFormData.entries()) {

  }

  try {
    const response = await fetch(`${BASE_URL}/insert`, {
      method: "POST", // Use POST for updates
      body: newFormData, // No need to set Content-Type, fetch will handle it automatically
    });

    const data = await response.json();



    if (response.ok) {
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: data.message || "Data updated successfully!",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: data.message || "Failed to update data!",
      });
    }
  } catch (error) {
    console.error("Failed to fetch CRUD table data:", error);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "An error occurred while updating the data!",
    });
    throw error;
  }
};


// export const insertFormBuilderData = async (formData, table_name) => {
//   const newFormData = new FormData();

//   // Append the form data fields
//   for (const [key, value] of Object.entries(formData)) {
//     newFormData.append(key, value);
//   }

//   newFormData.append("table_name", table_name);
//   newFormData.append("key", "12321");


//   
//   for (let pair of newFormData.entries()) {
//     
//   }


//   try {
//     const response = await fetch(`${BASE_URL}/insert`, {
//       method: "POST",
//       body: newFormData, // No need to set Content-Type, fetch will handle it automatically
//     });

//     if (!response.ok) {
//       
//       throw new Error(`Network response was not ok: ${response.statusText}`);
//     }

//     const data = await response.json();
//     
//     

//   } catch (error) {
//     console.error("Failed to fetch crud table data:", error);
//     throw error;
//   }
// }



// FetchApi.js

export const fetchTableFieldsOnly = async (tableName) => {

  let data = []
  try {
    const response = await fetch(
      `https://admissioninnepal.in/api/get-column/${tableName.replace("tbl_", "")}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: qs.stringify({ key: 12321 }), // Use env variables for sensitive data
      }
    );

    if (!response.ok) {
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }

    data = await response.json();

  }
  catch (err) {
    console.error("Failed to fetch table fields:", err);
  }
  return { data }
}

export const fetchTableFields = async (tableName) => {
  let data = [];
  let crudFieldData = [];
  let isTableVisible = false;

  try {
    const response = await fetch(
      `https://admissioninnepal.in/api/get-column/${tableName.replace("tbl_", "")}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: qs.stringify({ key: 12321 }), // Use env variables for sensitive data
      }
    );

    if (!response.ok) {
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }

    data = await response.json();

    // 
    isTableVisible = true;

    // Initialize CRUD field data with fetched table fields



    crudFieldData = data.map((field) => ({
      id: field.id,
      crud_id: "",
      field_name: field.COLUMN_NAME,
      field_label: "",
      field_type: "",

      attribute: "",
      is_required: 0,
      is_dependent: 0,
      dependency: "",
      default_value: "",

      is_dynamic: 0,
      dynamic_table: "",
      dynamic_field: "",
      dynamic_label: "",
      dynamic_condition: "",

      pattern: "",
      status: 0,
      is_visible: 0,
      col_size: "",
      position: "",

      options: ""
    }));
  } catch (err) {
    console.error("Failed to fetch table fields:", err);
  } finally {
  }



  return { crudFieldData, tableFields: data, isTableVisible };
};


// fetch column for dyamic select
export const fetchFieldsForDynamicSelect = async (tableName) => {
  let data = [];
  let crudFieldData = [];
  try {
    const response = await fetch(
      `https://admissioninnepal.in/api/get-column/${tableName.replace("tbl_", "")}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: qs.stringify({ key: 12321 }), // Use env variables for sensitive data
      }
    );

    if (!response.ok) {
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }

    data = await response.json();


  } catch (err) {
    console.error("Failed to fetch table fields:", err);
  }

  return { crudFieldData, tableFields: data };
};


// fetch table data for form builder

export const fetchtabledataFormBuilder = async (tableName, id = null) => {
  const newTableName = tableName.replace('tbl_', "");

  try {
    const response = await fetch(`https://admissioninnepal.in/Apis/crud/getdatanew_formbuilder/${newTableName}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qs.stringify({ key: 12321, id: id }),
    });

    if (response.ok) {
      const data = await response.json();

      return data; // Assuming the API response structure has a 'data' field    }
    }
  } catch (error) {
    console.error("Failed to fetch crud table data:", error);
    throw error;
  }
};


// fetch record from given table
export const fetchtabledataDisplay = async (tableName, id = null) => {


  const newTableName = tableName.replace('tbl_', "");

  try {
    const response = await fetch(`https://admissioninnepal.in/Apis/crud/getdatanew/${newTableName}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qs.stringify({ key: 12321, id: id }),
    });

    if (response.ok) {
      const data = await response.json();

      return data; // Assuming the API response structure has a 'data' field    }
    }
  } catch (error) {
    console.error("Failed to fetch crud table data:", error);
    throw error;
  }
};



// crudField list data below
export async function getCrudFieldList(setCrudFieldData, setFormData, crud_id, itemData, setCrudFieldDataStatus, table_data_id) {
  const newForm = {
    key: 12321,
    crud_id: crud_id,
  };

  try {

    const response = await fetch(`${BASE_URL}/crudfield-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qs.stringify(newForm),
    });


    const data = await response.json();

    const formBuilderData = data.data;

    console.log("formBuilderData")

    console.log(formBuilderData)

    if (!formBuilderData || formBuilderData.length === 0) {
      console.warn("No form builder data returned from the API.");
      return;
    }

    // Initialize formData state based on fetched fields
    const initialFormData = formBuilderData.reduce((acc, field) => {

      if (field.field_type === 'custom_checkbox' || field.field_type === 'custom_select_multiple', field.field_type === 'checkboxes') {
        acc[field.field_name] = []; // Initialize checkboxes as an array
      }
      else if (field.field_type === 'current_user_id') {
        acc[field.field_name] = localStorage.getItem('token');

      }

      else {
        acc[field.field_name] = ""; // Initialize other fields as an empty string
      }

      // Handle fields with options
      if (field.options && ['custom_select', 'custom_option', 'custom_checkbox', 'custom_select_multiple'].includes(field.field_type)) {
        try {
          const options = JSON.parse(field.options);

          if (field.field_type === 'custom_select_multiple') {
            const defaultOptions = options
              .filter(option => option.defaultValue === true)
              .map(option => option.value);

            acc[field.field_name] = defaultOptions; // Use this array for preselected values
          }

          // Handle custom_select and custom_option by setting a single default value
          if (['custom_select', 'custom_option'].includes(field.field_type)) {
            const defaultOption = options.find(option => option.defaultValue === true);
            if (defaultOption) {
              acc[field.field_name] = defaultOption.value;
            }
          }

          // Handle custom_checkbox by appending default values to the array
          if (field.field_type === 'custom_checkbox') {
            const defaultOptions = options
              .filter(option => option.defaultValue === true)
              .map(option => option.value);
            acc[field.field_name] = defaultOptions;
          }


        } catch (error) {
          console.error(`Error parsing options for field ${field.field_name}:`, error);
        }
      }
      return acc;

    }, {});


    // if (Array.isArray(itemData) && itemData.length !== 0) {
    if (typeof itemData === 'object' && Object.keys(itemData).length !== 0) {


      formBuilderData.map((field) => {


        // if input type == editor parse into json
        if (field.field_type === 'editor') {


          initialFormData[field.field_name] = itemData[field.field_name] ? JSON.parse(itemData[field.field_name]) : ""
        }
        else if (field.field_type === 'select_multiple' || field.field_type === 'checkboxes', field.field_type === 'range_double_slider') {

          initialFormData[field.field_name] = itemData[field.field_name] ? itemData[field.field_name].split(',') : [];
        }
        else {
          initialFormData[field.field_name] = itemData[field.field_name] ? itemData[field.field_name] : "";
        }

      })
    }


    setCrudFieldData(formBuilderData);
    // correct above object entries to key value of initialFormData data
    // update into setFromData
    setFormData(initialFormData);

    setCrudFieldDataStatus(true)

  } catch (error) {
    console.error("Fetching form data failed data failed", error);
  }
}

// 



// fetch crud table all data
export const fetchCrudTableData = async () => {
  try {
    const response = await fetch(`${BASE_URL}/crud-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qs.stringify({ key: keyvalue }),
    });


    if (!response.ok) {
      throw new Error("Network response was not ok: ");
    }

    const data = await response.json();

    return data.data; // Assuming the API response structure has a 'data' field
  } catch (error) {
    console.error("Failed to fetch crud table data:", error);
    throw error;
  }
};

// fetch crud table single data by id
export const fetchCrudTableDataById = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/crud-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qs.stringify({ key: keyvalue, id: id }),
    });


    if (!response.ok) {
      throw new Error("Network response was not ok: ");
    }

    const data = await response.json();

    return data.data; // Assuming the API response structure has a 'data' field
  } catch (error) {
    console.error("Failed to fetch crud table data:", error);
    throw error;
  }
};



export const fetchRegionData = async () => {
  try {
    const response = await fetch(`${BASE_URL}/region-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qs.stringify({ key: keyvalue }),
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    return data.data; // Assuming the API response structure has a 'data' field
  } catch (error) {
    console.error("Failed to fetch region data:", error);
    throw error;
  }
};

export const fetchCountryData = async () => {
  try {
    const response = await fetch(`${BASE_URL}/country-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qs.stringify({ key: keyvalue }),
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    return data.data; // Assuming the API response structure has a 'data' field
  } catch (error) {
    console.error("Failed to fetch country data:", error);
    throw error;
  }
};

export const fetchStateData = async () => {
  try {
    const response = await fetch(`${BASE_URL}/state-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qs.stringify({ key: keyvalue }),
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    return data.data; // Assuming the API response structure has a 'data' field
  } catch (error) {
    console.error("Failed to fetch state data:", error);
    throw error;
  }
};

export const fetchCityData = async () => {
  try {
    const response = await fetch(`${BASE_URL}/city-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qs.stringify({ key: keyvalue }),
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    return data.data; // Assuming the API response structure has a 'data' field
  } catch (error) {
    console.error("Failed to fetch city data:", error);
    throw error;
  }
};

export const fetchlocalityData = async () => {
  try {
    const response = await fetch(`${BASE_URL}/locality-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qs.stringify({ key: keyvalue }),
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    return data.data; // Assuming the API response structure has a 'data' field
  } catch (error) {
    console.error("Failed to fetch locality data:", error);
    throw error;
  }
};

export const fetchStreamData = async () => {
  try {
    const response = await fetch(`${BASE_URL}/stream-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qs.stringify({ key: keyvalue }),
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    return data.data; // Assuming the API response structure has a 'data' field
  } catch (error) {
    console.error("Failed to fetch Stream data:", error);
    throw error;
  }
};

export const fetchDegreeData = async () => {
  try {
    const response = await fetch(`${BASE_URL}/degree-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qs.stringify({ key: keyvalue }),
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    return data.data; // Assuming the API response structure has a 'data' field
  } catch (error) {
    console.error("Failed to fetch degree data:", error);
    throw error;
  }
};

export const fetchCourseData = async () => {
  try {
    const response = await fetch(`${BASE_URL}/course-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qs.stringify({ key: keyvalue }),
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    return data.data; // Assuming the API response structure has a 'data' field
  } catch (error) {
    console.error("Failed to fetch degree data:", error);
    throw error;
  }
};

export const fetchSpecializationData = async () => {
  try {
    const response = await fetch(`${BASE_URL}/specialization-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qs.stringify({ key: keyvalue }),
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    return data.data; // Assuming the API response structure has a 'data' field
  } catch (error) {
    console.error("Failed to fetch degree data:", error);
    throw error;
  }
};


export const fetchData = async (apiEndpoint, requestBody = {}, method = "POST") => {




  try {
    const response = await fetch(`${BASE_URL}/${apiEndpoint}`, {
      method: method,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: qs.stringify({ key: keyvalue }),
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();

    return data.data;
  } catch (error) {
    console.error("Fetching data failed", error);
    throw error;
  }
};





