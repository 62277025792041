import React, { useEffect, useState } from "react";
import qs from "qs"; // Import qs for URL encoding

import Header from "./Header";
import Sidebar from "./Sidebar";
import { Link, useParams } from "react-router-dom";

const Profile = () => {
  const { id } = useParams(); // Extract userId from the URL params
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(
          "https://admissioninnepal.in/api/single-users",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded", // Use URL-encoded format
            },
            body: qs.stringify({
              key: "12321", // Your key value
              id: id, // Dynamically passed userid
            }),
          }
        );

        const result = await response.json();

        if (result.responsecode === 200) {
          console.log("single user data");
          setUserDetails(result.data); // Store the user details in state
          console.log(result.data);
        } else {
          console.error("Failed to fetch user details");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, [id]);

  return (
    <>
      <Header />
      <Sidebar />

      <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
        {/*begin::Card header*/}
        <div className="card-header cursor-pointer" id="profile_header">
          {/*begin::Card title*/}
          <div className="card-title m-0" id="profile_title">
            <h3 className="fw-bold m-0">Profile Details</h3>
          </div>

          <Link
            to={`/edit-profile/${id}`}
            className="btn btn-sm btn-primary align-self-center"
            id="edit_profile_button"
          >
            Edit Profile
          </Link>
        </div>

        <div className="card-body p-9" id="profile_body">
          <div className="row mb-7" id="full_name_row">
            <label className="col-lg-4 fw-semibold text-dark">Full Name</label>
            <div className="col-lg-8">
              <span className="fw-bold fs-6 text-gray-800">
                {userDetails.users_name}
              </span>
            </div>
          </div>

          <div className="row mb-7" id="email_row">
            <label className="col-lg-4 fw-semibold text-dark">Email</label>
            <div className="col-lg-8 fv-row">
              <span className="fw-semibold text-gray-800 fs-6">
                {userDetails.email}
              </span>
            </div>
          </div>

          <div className="row mb-7" id="contact_row">
            <label className="col-lg-4 fw-semibold text-dark">
              Contact
              <span
                className="ms-1"
                data-bs-toggle="tooltip"
                title="Phone number must be active"
              >
                <i className="ki-duotone ki-information fs-7"></i>
              </span>
            </label>
            <div className="col-lg-8 d-flex align-items-center">
              <span className="fw-bold fs-6 text-gray-800 me-2">
                {userDetails.contact}
              </span>
              <span className="badge badge-success">Verified</span>
            </div>
          </div>

          <div className="row mb-7" id="designation_row">
            <label className="col-lg-4 fw-semibold text-dark">
              Designation
            </label>
            <div className="col-lg-8">
              <a
                href="#"
                className="fw-semibold fs-6 text-gray-800 text-hover-primary"
              >
                {userDetails.user_type}
              </a>
            </div>
          </div>

          <div className="row mb-7" id="location_row">
            <label className="col-lg-4 fw-semibold text-dark">
              Location
              <span
                className="ms-1"
                data-bs-toggle="tooltip"
                title="Country of origination"
              >
                <i className="ki-duotone ki-information fs-7"></i>
              </span>
            </label>
            <div className="col-lg-8">
              <span className="fw-bold fs-6 text-gray-800">
                {userDetails.location}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
