import { mappls, mappls_plugin } from "mappls-web-maps";
import { useEffect, useRef, useState } from "react";
import axios from "axios";

const mapplsClassObject = new mappls();
const mapplsPluginObject = new mappls_plugin();

const map_my_india_api = process.env.REACT_APP_MAP_MY_INDIA_API;

const MapData = () => {
  const mapRef = useRef(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [address, setAddress] = useState("");
  const [suggestions, setSuggestions] = useState({});

  const loadObject = {
    map: true,
    layer: "raster", // Optional Default Vector
    version: "3.0", // Optional for other versions
    libraries: ["polydraw"], // Optional for Polydraw and airspaceLayers
    plugins: ["direction"], // Optional for All the plugins
  };

  // Function to fetch suggestions from your backend API
  const fetchSuggestions = async (query) => {
    try {
      const response = await axios.get(
        `https://admissioninnepal.in/api/map_data/${query}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (response.data.copResults) {
        setSuggestions(response.data.copResults); // Set suggestions from the response
      } else {
        setSuggestions({}); // If no results, clear suggestions
      }
    } catch (error) {
      console.error("Error fetching address suggestions:", error);
    }
  };

  // Handle address input change
  const handleAddressChange = (e) => {
    const query = e.target.value;
    setAddress(query);

    if (query.length > 2) {
      // Fetch suggestions if query length is greater than 2
      fetchSuggestions(query);
    } else {
      setSuggestions({});
    }
  };

  useEffect(() => {
    mapplsClassObject.initialize(
      map_my_india_api,

      loadObject,

      () => {
        const newMap = mapplsClassObject.Map({
          id: "map",
          properties: {
            center: [28.633, 77.2194],
            zoom: 4,
          },
        });

        newMap.on("load", () => {
          setIsMapLoaded(true);
        });
        mapRef.current = newMap;
      }
    );


    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
      }
    };
  }, []);


  return (
    <div style={{ width: "100%", height: "99vh", display: "inline-block" }}>
      <input
        type="text"
        value={address}
        onChange={handleAddressChange}
        placeholder="Enter address"
        style={{
          position: "absolute",
          top: "10px",
          left: "10px",
          padding: "8px",
          zIndex: 9999,
          width: "300px",
        }}
      />

      {/* Render suggestions if available */}
      {Object.keys(suggestions).length > 0 && (
        <ul
          style={{
            position: "absolute",
            top: "50px",
            left: "10px",
            backgroundColor: "white",
            zIndex: 9999,
            width: "300px",
            listStyle: "none",
            padding: "0",
            margin: "0",
            border: "1px solid #ccc",
          }}
        >
          {/* Map over the suggestions and render list items */}
          <li
            style={{
              padding: "8px",
              borderBottom: "1px solid #ddd",
              cursor: "pointer",
            }}
            onClick={() => {
              const fullAddress = `${suggestions.houseNumber} ${suggestions.houseName} ${suggestions.poi} ${suggestions.street}`;
              setAddress(fullAddress.trim()); // Update input with selected suggestion
              setSuggestions({}); // Clear suggestions after selection
            }}
          >
            {/* Display multiple fields from the suggestion */}
            {suggestions.houseNumber && <span>{suggestions.houseNumber} </span>}
            {suggestions.houseName && <span>{suggestions.houseName} </span>}
            {suggestions.poi && <span>{suggestions.poi}, </span>}
            {suggestions.street && <span>{suggestions.street}</span>}

          </li>
        </ul>
      )}

      <div id="map" style={{ width: "100%", height: "100%" }}>
        {isMapLoaded}
      </div>
    </div>
  );
};


export default MapData
