

import React, { useEffect, useState } from "react";
import qs from "qs"; // Import qs for URL encoding
import { toast, ToastContainer } from "react-toastify"; // Import react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import toast CSS
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { useNavigate, useParams } from "react-router-dom";

const EditProfile = () => {
  const navigate = useNavigate(); // Hook for navigation

  const { id } = useParams(); // Extract userId from the URL params

  const [userDetails, setUserDetails] = useState({
    users_name: "",
    email: "",
    contact: "",
    user_type: "",
    location: "",
    slug: "", // Add slug to state
  });

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(
          "https://admissioninnepal.in/api/single-users",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded", // Use URL-encoded format
            },
            body: qs.stringify({
              key: "12321", // Your key value
              id: id, // Dynamically passed userid
            }),
          }
        );

        const result = await response.json();

        if (result.responsecode === 200) {
          console.log("single user data");
          setUserDetails(result.data); // Store the user details in state
          console.log(result.data);
        } else {
          console.error("Failed to fetch user details");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://admissioninnepal.in/api/add-edit-users",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: qs.stringify({
            key: "12321", // Your key value
            id: id, // User id to update
            users_name: userDetails.users_name,
            email: userDetails.email,
            contact: userDetails.contact,
            user_type: userDetails.user_type,
            location: userDetails.location,
            slug: userDetails.slug, // Include the slug in the request body
          }),
        }
      );

      const result = await response.json();
      if (result.responsecode === 200) {

        toast.success("Profile updated successfully!");
        setTimeout(() => {
          navigate(`/profile/${id}`);
        }, 2000); // 2 seconds delay before navigating
      } else {
        toast.error("Failed to update profile");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("An error occurred while updating the profile");
    }
  };

  return (
    <>
      <Header />
      <Sidebar />

      <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
        <div className="card-header cursor-pointer" id="profile_header">
          <div className="card-title m-0" id="profile_title">
            <h3 className="fw-bold m-0">Update Profile</h3>
          </div>

          {/* <Link
            to={`/edit-profile/${id}`}
            className="btn btn-sm btn-primary align-self-center"
            id="edit_profile_button"
          >
           Go Back
          </Link> */}
        </div>

        <div className="card-body p-9" id="profile_body">
          <ToastContainer />

          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="mb-3 col-lg-6">
                <label className="form-label">Full Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="users_name"
                  value={userDetails.users_name}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-3  col-lg-6">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={userDetails.email}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-3  col-lg-6">
                <label className="form-label">Contact</label>
                <input
                  type="text"
                  className="form-control"
                  name="contact"
                  value={userDetails.contact}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-3  col-lg-6">
                <label className="form-label">Designation</label>
                <input
                  type="text"
                  className="form-control"
                  name="user_type"
                  value={userDetails.user_type}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-3  col-lg-6">
                <label className="form-label">Location</label>
                <input
                  type="text"
                  className="form-control"
                  name="location"
                  value={userDetails.location}
                  onChange={handleChange}
                />
              </div>

              <button type="submit" className="btn btn-primary">
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
