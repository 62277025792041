
import React, { useState, useEffect } from 'react';

const DashboardCard = ({ title, count, label, iconClass, backgroundColor }) => {
    const [displayedCount, setDisplayedCount] = useState(0);

    useEffect(() => {
        let currentCount = 0;
        const increment = Math.ceil(count / 100); // Set the increment value based on the count
        const interval = setInterval(() => {
            currentCount += increment;
            if (currentCount >= count) {
                currentCount = count; // Ensure it stops at the exact count
                clearInterval(interval); // Stop the interval
            }
            setDisplayedCount(currentCount);
        }, 1); // Speed of the counter, adjust as needed

        return () => clearInterval(interval); // Clean up the interval when the component unmounts
    }, [count]); // Re-run the effect if the count changes

    return (
        <>

            <div className="col-lg-3 col-md-6 ">
                <div className="card" style={{ backgroundColor }}>
                    <div className="card-body d-flex flex-column">

                        <h5 className="card-title text-gray-900 fw-bold fs-3">{title}</h5>
                        
                        <div className="d-flex align-items-center mt-5">
                            {/* Icon */}
                            <div className="symbol symbol-40px me-5">
                                <div className="symbol-label bg-light">
                                    <i className={`${iconClass} fs-1 text-gray-900`}></i>
                                </div>
                            </div>
                            {/* Count and label */}
                            <div>
                                <div className="fs-5 text-gray-900 fw-bold lh-1">{displayedCount}</div>
                                <div className="fs-7 text-gray-600 fw-bold">{label}</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default DashboardCard