





export const deleteFile = (e, setFormData, file_name) => {
  e.preventDefault()
  setFormData((prevData) => {

    const newFormData = {
      ...prevData,
      [file_name]: ""
    }

    return newFormData;
  })

}

export const deleteMultipleFile = (e, setFormData, field_name, file, index) => {
  e.preventDefault();

  setFormData((prevData) => {
    let multipleFileCheck = Array.isArray(prevData[field_name])
      ? prevData[field_name]
      : prevData[field_name]?.split(',') || [];

    // Filter out the file at the provided index
    let updatedFiles = multipleFileCheck.filter((_, i) => i !== index);

    // Update the formData
    return {
      ...prevData,
      [field_name]: updatedFiles.join(','), // Store as a string, or directly as an array if required
    };
  });
};


export const MultiRangeSliderInput = (e, setFormData, field_name) => {

  setFormData((prevData) => {
    // Check if values actually changed to prevent unnecessary updates
    const prevMin = prevData[field_name]?.[0];
    const prevMax = prevData[field_name]?.[1];

    if (prevMin !== e.minValue || prevMax !== e.maxValue) {

      const updatedData = [e.minValue, e.maxValue]; // Only update if values changed
      return {
        ...prevData,
        [field_name]: updatedData,
      };
    }

    // If no change, return the previous state
    return prevData;
  });
};


// correct above code to store double pointer value in an Array min value at zeroth index and max value at first index.


const fillColor = (sliderValues, sliderMaxValue) => {

  const percent1 = (sliderValues[0] / sliderMaxValue) * 100;
  const percent2 = (sliderValues[1] / sliderMaxValue) * 100;
  const sliderTrack = document.querySelector('.slider-track');
  if (sliderTrack) {
    sliderTrack.style.background = `linear-gradient(to right, #dadae5 ${percent1}% , #3264fe ${percent1}% , #3264fe ${percent2}%, #dadae5 ${percent2}%)`;
  }

};


// double pointer range inptu type funtions
export const slideOne = (e, minGap, setFormData, field_name, sliderMaxValue) => {

  const newValue = parseInt(e.target.value);

  setFormData((prevData) => {
    const updatedData = [...(prevData[field_name] || [0, 100])];

    if (prevData[field_name][1] - newValue <= minGap) {
      updatedData[0] = prevData[field_name][1] - minGap;
    } else {
      updatedData[0] = newValue;
    }

    // Update the formData state
    const newFormData = {
      ...prevData,
      [field_name]: updatedData,
    };

    // Call fillColor using formData
    fillColor(newFormData[field_name], sliderMaxValue);

    return newFormData;
  });

}

export const slideTwo = (e, minGap, setFormData, field_name, sliderMaxValue) => {
  const newValue = parseInt(e.target.value);

  setFormData((prevData) => {
    const updatedData = [...(prevData[field_name] || [0, 100])];

    // Ensure minGap constraint is maintained
    if (newValue - prevData[field_name][0] <= minGap) {
      updatedData[1] = prevData[field_name][0] + minGap;
    } else {
      updatedData[1] = newValue;
    }

    // Update the formData state
    const newFormData = {
      ...prevData,
      [field_name]: updatedData,
    };

    // Call fillColor using formData
    fillColor(newFormData[field_name], sliderMaxValue);

    return newFormData;
  });
};


export const filterTableData = (tableData, id, dependency, formData, dynamic_condition) => {
  if (!tableData[id]) return [];

  return tableData[id].filter(item => item[dependency] == formData[dynamic_condition]);
};



// Uncaught TypeError: Spread syntax requires ...iterable[Symbol.iterator] to be a function



export const handleSelectChange = (e, setFormData, setSelectedValue) => {

  const { name, value } = e.target;
  // Update the selected value in state

  setSelectedValue(value)



  // Update the form data with the selected value
  setFormData((prevData) => ({ ...prevData, [name]: value }));
};


export const handleCustomSelectMultipleOption = (e, setFormData, setSelectedValue) => {

  const { name, value } = e.target;
  // Update the selected value in state

  setSelectedValue(value)

  // Update the form data with the selected value
  setFormData((prevData) => ({ ...prevData, [name]: value }));
};


export const handleSingleRangeChange = (newValues,setFormData,field_name) => {
  setFormData((prevData) => ({
    ...prevData,
    [field_name]: newValues[0], // Update formData with the first value of the range array
  }));
};


// getSelectOption for multiple select

export function handleInputChange(e, setFormData) {
  const { name, value, type, checked, files } = e.target;

  let finalValue;

  if (type === 'file') {
    finalValue = files[0]; // Store the single file
  } else if (type === 'range') {
    finalValue = Number(value);
  } // Convert range value to a number
  else if (type === 'radio') {
    finalValue = value // Set 1 for "yes" and 0 for "no"
  } else if (type === 'checkbox') {
    finalValue = checked ? 1 : 0; // Set 1 if checked, else 0
  } else {
    finalValue = value;
  }

  setFormData((prevData) => ({
    ...prevData,
    [name]: finalValue,
  }));
}


export function handleMultipleFileChange(e, setFormData) {
  const { name, files } = e.target;

  setFormData((prevData) => {
    // Convert the FileList object to an array
    const newFiles = Array.from(files);

    // Merge the new files with any existing files in the state
    const updatedFiles = [...newFiles];

    return {
      ...prevData,
      [name]: updatedFiles,
    };

  });


}


export function getSelectOption(id, tableData, dynamic_field, dynamic_label) {

  return tableData[id]?.map(item => ({
    label: item[dynamic_label],
    value: item[dynamic_field]
  })) || [];

}

// multiple select not selecting multiple value. why?

export const handleSelectMultipleOption = (selectedOptions, formData, setFormData,field_name) => {

  setFormData({
    ...formData,
    [field_name]: selectedOptions.map(option => option.value)
  });
};


export function handleTextEditorChange(e, name, setFormData) {

  const { htmlValue, textValue } = e

  // const editorObj = {
  //   htmlValue: htmlValue,
  // }


  
  setFormData((prevData) => ({
    ...prevData,
    [name]: htmlValue,
  }));

}



export const handleRadioChange = (e, setFormData) => {

  const { name, value } = e.target

  setFormData((prevData) => ({
    ...prevData,
    [name]: value
  }))
};

export const handleCheckboxChange = (e, setFormData) => {
  const { name, value } = e.target
  setFormData((prevData) => ({
    ...prevData,
    [name]: value
  }))
};



export function handleChekboxesChange(e, value, setFormData) {
  const { name, checked } = e.target;

  setFormData((prevData) => {
    // Get the current array of values for this checkbox group
    const currentValues = Array.isArray(prevData[name]) ? prevData[name] : [];

    // If checked, add the value; otherwise, remove it
    const newValues = checked
      ? [...currentValues, value]
      : currentValues.filter((v) => v !== value);

    return {
      ...prevData,
      [name]: newValues, // Update the checkbox group with the new array
    };

  });


}


