import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const INACTIVITY_TIME_LIMIT = 24 * 60 * 1000;
const CHECK_INTERVAL = 1000; // Check every 1 second

const Login = () => {
  const [loading, setLoading] = useState(false);

  const keyvalue = "12321";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    const delay = 2000; // Delay in milliseconds (e.g., 2000ms = 2 seconds)

    try {
      const response = await axios.post(
        "https://admissioninnepal.in/api/login",
        qs.stringify({
          email,
          password,
          key: keyvalue,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (response.data.respocecode === 200) {
        const userData = response.data.data;
        const tokenExpiryTime = 60 * 60 * 1000; // 1 hour

        // Store token and other details in localStorage
        localStorage.setItem("token", userData.id);

        localStorage.setItem("userName", userData.users_name); // Store user name
        localStorage.setItem("userEmail", userData.email); // Store user name

        localStorage.setItem("tokenExpiry", Date.now() + tokenExpiryTime);
        localStorage.setItem("lastActivity", Date.now());

        localStorage.setItem("userStatus", "1"); // Assuming status "1" means active

        // Log login time and store it
        const loginTime = new Date().toISOString();
        localStorage.setItem("loginTime", loginTime); // Store login time

        try {
          const insertResponse = await axios.post(
            "https://admissioninnepal.in/Apis/Log/login_status",
            qs.stringify({
              user_id: userData.id, // Correct user ID field
              status: "1", // Correct login time
            }),
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          );


          if (insertResponse.data.responsecode === 200) {
          } else {
            console.error(
              "Data insertion failed:",
              insertResponse.data.message
            );
          }
        } catch (error) {
          console.error("An error occurred while inserting data:", error);
          // This will catch the 500 error and show it in the console
        }

        const welcomeMessage =
          userData.user_type === "1" ? "Welcome Admin!" : "Welcome User!";

        setTimeout(() => {
          Swal.fire({
            icon: "success",
            title: "Login Successful",
            text: welcomeMessage,
          }).then(() => {
            if (userData.user_type === "1") {
              navigate("/dashboardpage");
            } else {
              navigate("/userdashboard");
            }
          });
        }, delay); // Show Swal after the delay
      } else {
        setTimeout(() => {
          Swal.fire({
            icon: "error",
            title: "Login Failed",
            text: response.data.message || "Invalid email or password",
          });
        }, delay); // Show Swal after the delay
      }
    } catch (error) {
      console.error("Login failed", error);
      setTimeout(() => {
        Swal.fire({
          icon: "error",
          title: "Login Failed",
          text:
            error.response?.data?.message ||
            "An error occurred during login. Please try again later.",
        });
      }, delay); // Show Swal after the delay
    } finally {
      setLoading(false); // Stop loading regardless of success or failure
    }
  };

  useEffect(() => {
    const events = ["click", "mousemove", "keypress"];
    const resetTimer = () => {
      localStorage.setItem("lastActivity", Date.now());
    };

    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });
    };
  }, []);

  useEffect(() => {
    const checkInactivity = () => {
      const lastActivity = parseInt(localStorage.getItem("lastActivity"), 10);
      const tokenExpiry = parseInt(localStorage.getItem("tokenExpiry"), 10);

      if (
        Date.now() - lastActivity > INACTIVITY_TIME_LIMIT ||
        Date.now() > tokenExpiry
      ) {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiry");
        localStorage.removeItem("lastActivity");
        navigate("/"); // Redirect to login page
      }
    };

    const interval = setInterval(checkInactivity, CHECK_INTERVAL);

    return () => clearInterval(interval);
  }, [navigate]);

  return (
    <div className="d-flex flex-column flex-root" id="kt_app_root">
      {/*begin::Authentication - Sign-in */}
      <div className="d-flex flex-column flex-lg-row flex-column-fluid">
        {/*begin::Body*/}
        <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
          {/*begin::Form*/}
          <div className="d-flex flex-center flex-column flex-lg-row-fluid">
            {/*begin::Wrapper*/}
            <div className="w-lg-500px p-10">
              {loading ? (
                <div className="shimmer-container">
                  <div className="shimmer" style={{ height: "40px" }}></div>
                </div>
              ) : (
                // Begin Form

                <form className="form w-100" onSubmit={handleLogin}>
                  <div className="text-center mb-11">
                    <h1 className="text-gray-900 fw-bolder mb-3">Sign In</h1>
                  </div>

                  <div className="fv-row mb-8 ">
                    <input
                      type="email"
                      placeholder="Email"
                      className="form-control bg-transparent"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>

                  <div className="fv-row mb-3">
                    <input
                      type="password"
                      placeholder="Password"
                      className="form-control bg-transparent"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>

                  <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                    <div></div>
                    <Link to="/forgot-password" className="link-primary">
                      Forgot Password?
                    </Link>
                  </div>

                  <div className="d-grid mb-10">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Sign In"}
                    </button>
                  </div>

                  <div className="text-gray-500 text-center fw-semibold fs-6">
                    Not a Member yet?
                    <Link to="/signup" className="link-primary mx-3">
                      Sign up
                    </Link>
                  </div>
                </form>
              )}
            </div>
          </div>

          {/*end::Footer*/}
        </div>

        <div
          className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2"
          style={{ backgroundImage: "url(assets/media/misc/auth-bg.png)" }}
        >
          {/*begin::Content*/}
          <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
            {/*begin::Logo*/}
            <a href="" className="mb-0 mb-lg-12">
              <img
                alt="Logo"
                src="assets/media/misc/auth-screens.png"
                className="h-60px h-lg-75px"
              />
            </a>

            <img
              className="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20"
              src="assets/media/misc/auth-screens.png"
              alt=""
            />

            <h1 className="d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7">
              Fast, Efficient and Productive
            </h1>

            <div className="d-none d-lg-block text-white fs-base text-center">
              In this kind of post,
              <a href="" className="opacity-75-hover text-warning fw-bold me-1">
                the blogger
              </a>
              introduces a person they’ve interviewed
              <br />
              and provides some background information about
              <a href="" className="opacity-75-hover text-warning fw-bold me-1">
                the interviewee
              </a>
              and their
              <br />
              work following this is a transcript of the interview.
            </div>
            {/*end::Text*/}
          </div>
          {/*end::Content*/}
        </div>
      </div>
    </div>
  );
};

export default Login;
