import React, { createContext, useEffect, useState } from "react";
import { isMobile, isTablet, isBrowser } from "react-device-detect";

import axios from "axios";
import moment from "moment"; // You can use moment.js for easier date handling
import qs from "qs";
import { Chart, registerables } from "chart.js"; // Import registerables
import Sidebar from "../components/Sidebar.jsx";
import { PieChart } from "@mui/x-charts";
import { desktopOS, valueFormatter } from "../components/WebUsage.jsx";
import Header from "../components/Header.jsx";
import { Bar } from "react-chartjs-2";
import Line from "../components/Line.jsx";
import DashboardCard from "./DashboardCard.js";
import {
  fetchCrudTableData,
  fetchSidebarData,
} from "../customhook/FetchApi.jsx";

import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import { Link } from "react-router-dom";
import useStore from "../zustand/ReactStore"; // Import the Zustand store

// Register all necessary components

Chart.register(...registerables);

const CrudTableData = createContext();

const Saledata = {
  labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
  datasets: [
    {
      label: "Sales",
      data: [12000, 15000, 13000, 14000],
      backgroundColor: "rgba(75, 192, 192, 0.6)",
      borderColor: "rgba(75, 192, 192, 1)",
      borderWidth: 1,
    },
  ],
};

const options = {
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

function Dashboard() {
  const [logData, setLogData] = useState([]);
  const [counts, setCounts] = useState({
    college: 0,
    course: 0,
    exam: 0,
  });

  useEffect(() => {
    const fetchCountData = async () => {
      const tables = ["tbl_college", "tbl_course", "tbl_exam"];
      const counts = {};
      const key = 12321; // Example key for any additional tracking

      try {
        // Map over the tables array to create fetch requests
        const requests = tables.map((table) => {
          const bodyData = qs.stringify({ key });

          return fetch(`https://admissioninnepal.in/api/get_count/${table}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: bodyData,
          }).then((response) => response.text());
        });

        // Wait for all the fetch requests to resolve
        const results = await Promise.all(requests);

        // Assign the fetched counts to the appropriate keys
        tables.forEach((table, index) => {
          const tableKey = table.split("_")[1]; // Extract 'college', 'course', or 'exam'
          counts[tableKey] = parseInt(results[index], 10);
        });

        setCounts(counts); // Update the state with the fetched data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCountData();
  }, []);

  const collegeEntries = [
    { name: "IIT Delhi", location: "Delhi", addedTime: "12:00 PM" },
    { name: "MIT Boston", location: "Boston", addedTime: "10:30 AM" },
    { name: "IIT Delhi", location: "Delhi", addedTime: "12:00 PM" },
    { name: "MIT Boston", location: "Boston", addedTime: "10:30 AM" },
    { name: "IIT Delhi", location: "Delhi", addedTime: "12:00 PM" },
    { name: "MIT Boston", location: "Boston", addedTime: "10:30 AM" },
    { name: "IIT Delhi", location: "Delhi", addedTime: "12:00 PM" },
    { name: "MIT Boston", location: "Boston", addedTime: "10:30 AM" },
    // More entries...
  ];
  const students = [
    {
      id: "XY56987",
      name: "John Deo",
      course: "MBBS",
      location: "New York",
      registeredDate: "2024-10-01",
    },
    {
      id: "XY56988",
      name: "Jane Smith",
      course: "B.Tech",
      location: "Los Angeles",
      registeredDate: "2024-09-21",
    },
    {
      id: "XY56989",
      name: "Michael Roe",
      course: "B.Sc",
      location: "Chicago",
      registeredDate: "2024-09-15",
    },
    {
      id: "XY56987",
      name: "John Deo",
      course: "MBBS",
      location: "New York",
      registeredDate: "2024-10-01",
    },
    {
      id: "XY56988",
      name: "Jane Smith",
      course: "B.Tech",
      location: "Los Angeles",
      registeredDate: "2024-09-21",
    },
    {
      id: "XY56989",
      name: "Michael Roe",
      course: "B.Sc",
      location: "Chicago",
      registeredDate: "2024-09-15",
    },
    // Add more student data here
  ];

  const blogs = [
    { id: 1, name: "Blog Post 1", createdAt: "2024-09-21T10:30:00Z" },
    { id: 2, name: "Blog Post 2", createdAt: "2024-10-01T14:00:00Z" },
    // Add more blog data here
  ];

  const [examUpdates, setExamUpdates] = useState([
    {
      subject: "Computer Science",
      examDate: "25 July 2024",
      time: "8:00 AM",
      updated: "20 Jul 2024, 10:00 am",
    },
    {
      subject: "Mathematics",
      examDate: "30 July 2024",
      time: "9:00 AM",
      updated: "18 Jul 2024, 3:30 pm",
    },
    {
      subject: "Physics",
      examDate: "1 August 2024",
      time: "11:00 AM",
      updated: "22 Jul 2024, 2:15 pm",
    },
  ]);

  const getDeviceName = () => {
    if (isMobile) return "Mobile";
    if (isTablet) return "Tablet";
    if (isBrowser) return "Desktop";
    return "Unknown Device";
  };
  const fetchSidebarDataZustandStore = useStore(
    (state) => state.fetchSidebarDataZustandStore
  ); // Fetch method from store
  // const sidebarDataFromZustandStore = useStore((state) => state.sidebarDataFromZustandStore); // Access sidebar data from store
  const updateSidebarDatafromZustand = useStore(
    (state) => state.updateSidebarDatafromZustand
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sidebardata = await fetchSidebarData();
        await updateSidebarDatafromZustand(sidebardata);
      } catch (error) {
        console.error("Error fetching sidebar data:", error); // Handle any errors
      }
    };

    fetchData(); // Call the async function
  }, [fetchSidebarDataZustandStore, updateSidebarDatafromZustand]); // Include dependencies if needed

  useEffect(() => {
    // Fetching login data from the API
    const fetchLogData = async () => {
      try {
        const response = await axios.get(
          "https://admissioninnepal.in/Apis/log/loginList"
        );
        if (response.data.responsecode === 200) {
          const enhancedData = response.data.data.map((log) => ({
            ...log,
            device: getDeviceName(),
          }));
          setLogData(enhancedData);
        } else {
          console.error("Failed to fetch data:", response.data.message);
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    };

    fetchLogData();
  }, []);

  // Filter logins and logouts
  const logins = logData.filter((log) => log.status === "1");
  const logouts = logData.filter((log) => log.status === "0");

  let totalDuration = 0;

  // Calculate total duration
  logins.forEach((login) => {
    const loginTime = moment(login.timestamp);
    const logout = logouts.find(
      (log) => log.user_id === login.user_id && log.id > login.id
    );
    if (logout) {
      const logoutTime = moment(logout.timestamp);
      totalDuration += logoutTime.diff(loginTime, "minutes"); // Calculate duration in minutes
    }
  });

  const getStatusLabel = (status) => {
    return status === "1" ? "Login" : "Logout";
  };

  const data = {
    labels: "Data",
    datasets: [
      {
        label: "My First Dataset",
        data: [65, 59, 80, 81, 56, 55, 40],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(201, 203, 207, 0.2)",
        ],
        borderColor: [
          "rgb(255, 99, 132)",
          "rgb(255, 159, 64)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(201, 203, 207)",
        ],
        borderWidth: 1,
      },
    ],
  };

  // Data for the line graph
  const lineData = {
    labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
    datasets: [
      {
        label: "Weekly User Signups",
        data: [30, 70, 100, 80],
        fill: false,
        backgroundColor: "rgba(153, 102, 255, 0.6)",
        borderColor: "rgba(153, 102, 255, 1)",
      },
    ],
  };

  useEffect(() => {
    AOS.init();
  }, []);

  const [crudData, setCrudData] = useState([]);

  useEffect(() => {
    const getCrudTable = async () => {
      const crudtable = await fetchCrudTableData();
      setCrudData(crudtable);
    };
    getCrudTable();
  }, []);

  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <Sidebar />
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <Header />

            <div
              className="content d-flex flex-column flex-column-fluid"
              id="kt_content cards"
            >
              {/*begin::Toolbar*/}
              <div className="toolbar" id="kt_toolbar">
                {/*begin::Container*/}
                <div
                  id="kt_toolbar_container"
                  className="container-fluid d-flex flex-stack"
                >
                  {/*begin::Page title*/}
                  <div
                    id="content_wrapper"
                    className="page-title d-flex justify-content-between align-items-center flex-wrap lh-1"
                    data-kt-swapper="true"
                    data-kt-swapper-mode="prepend"
                    data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                  >
                    <div id="multipurpose_section">
                      <h1 className="d-flex align-items-center text-gray-900 fw-bold my-1 fs-3">
                        Admin Dashboard
                      </h1>
                    </div>

                    <div id="breadcrumb_section">
                      <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-1">
                        {/* <li className="breadcrumb-item text-muted"> */}
                        <Link to="index.html" className="bread-crumb-text">
                          Home
                        </Link>

                        {/* </li> */}

                        <li className="breadcrumb-item">
                          <span className="bullet bg-gray-300 w-5px h-2px" />
                        </li>

                        <li className="">
                          <Link
                            to="/dashboardpage"
                            className="custom-dashboard-link bread-crumb-text"
                          >
                            Dashboards
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="row py-5">
              {/* Dashboard Cards */}

              <section id="cardss">
                <div className="row g-5" id="cardss">
                  {/* Card 1 */}
                  <DashboardCard
                    title="Total Colleges"
                    count={counts.college} // Dynamic count from countData
                    label="Colleges"
                    iconClass="fas fa-university"
                    backgroundColor="#F7D9E3"
                  />

                  <DashboardCard
                    title="Total Courses"
                    count={counts.course} // Fallback if data is not loaded yet
                    label="Courses"
                    iconClass="fas fa-school"
                    backgroundColor="#CBD4F4"
                  />


                  <DashboardCard
                    title="Total Exams"
                    count={counts.exam} // Fallback if data is not loaded yet
                    label="Exams"
                    iconClass="fas fa-graduation-cap"
                    backgroundColor="#CBF0F4"
                  />
                  <DashboardCard
                    title="Total Colleges"
                    count={counts.college} // Dynamic count from countData
                    label="Colleges"
                    iconClass="fas fa-university"
                    backgroundColor="#F7D9E3"
                  />
                  <DashboardCard
                    title="Total Courses"
                    count={counts.course} // Fallback if data is not loaded yet
                    label="Courses"
                    iconClass="fas fa-school"
                    backgroundColor="#CBD4F4"
                  />
                  <DashboardCard
                    title="Total Exams"
                    count={counts.exam} // Fallback if data is not loaded yet
                    label="Exams"
                    iconClass="fas fa-graduation-cap"
                    backgroundColor="#CBF0F4"
                  />
                  <DashboardCard
                    title="Total Courses"
                    count={counts.course} // Fallback if data is not loaded yet
                    label="Courses"
                    iconClass="fas fa-school"
                    backgroundColor="#CBD4F4"
                  />
                  <DashboardCard
                    title="Total Exams"
                    count={counts.exam} // Fallback if data is not loaded yet
                    label="Exams"
                    iconClass="fas fa-graduation-cap"
                    backgroundColor="#CBF0F4"
                  />
                </div>
              </section>

              <section id="cardss" className="mt-5 px-4 py-2">
                <div className="row g-5 ms-2">
                  {" "}
                  <div className="col-md-6">
                    <div
                      className="card mb-4 shadow-sm"
                      id="recent-college-entries"
                    >
                      <div
                        className="card-header text-center"
                        id="college-header"
                      >
                        <h5 className="fw-bold text-light xms-4">
                          Recent College Entries
                        </h5>
                      </div>
                      <div className="card-body">
                        <ul className="list-group list-group-flush">
                          {collegeEntries.map((college, index) => (
                            <li
                              key={index}
                              className="d-flex align-items-center list-group-item "
                              id={`college-entry-${index}`}
                            >
                              <div className="dot me-2" />{" "}
                              {/* Dot before each entry */}
                              <span className="text-muted fs-7 college-entry">
                                <span className="fw-bold college-name">
                                  {college.name}
                                </span>{" "}
                                from Location:{" "}
                                <span className="college-location">
                                  {college.location}
                                </span>
                                , Added at:{" "}
                                <span className="college-added-time">
                                  {college.addedTime}
                                </span>
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* Recent Exam Dates Section */}
                  <div className="col-md-6">
                    <div className="card card-flush pt-3 mb-5 mb-xl-10">
                      {/*begin::Card header*/}
                      <div className="card-header" id="college-header">
                        {/*begin::Card title*/}
                        <div className="card-title">
                          <h2 className="text-dark">Recent Exam Update</h2>
                        </div>

                        <div className="card-toolbar">
                          <a href="#" className="btn btn-light-primary">
                            View Exam
                          </a>
                        </div>
                        {/*end::Card toolbar*/}
                      </div>
                      <hr />
                      <div className="card-body pt-0">
                        <div className="list-responsive">
                          <ul id="exam-updates-list">
                            {/* Dynamically render list using map */}
                            {examUpdates.map((update, index) => (
                              <li key={index}>
                                <span>{index + 1}. </span>{" "}
                                {/* Displaying serial number */}
                                <strong>{update.subject}</strong> exam scheduled
                                on
                                <strong> {update.examDate} </strong> at{" "}
                                {update.time}, last updated:{" "}
                                <span>{update.updated}</span>.
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>

                      {/*end::Card body*/}
                    </div>
                  </div>
                </div>
              </section>

              {/* Recent College Entries Section */}

              {/* login activity */}
              <section id="cardss" className="mt-5 px-4 py-2">
                <div className="row g-5 ms-2">
                  <div className="col-lg-6">
                    {/*begin::Card*/}
                    <div className="card card-flush h-md-100">
                      {/*begin::Card header*/}
                      <div className="card-header d-flex justify-content-between align-items-center">
                        {/* Card title */}
                        <div className="card-title">
                          <h2>Recent Blog</h2>
                        </div>

                        {/* Card toolbar */}
                        <div className="card-toolbar">
                          <a href="#" className="btn btn-light-primary">
                            View Blog
                          </a>
                        </div>
                      </div>
                      <div className="card-body pt-1">
                        {/*begin::Users*/}

                        {/* Table to display Recent Blog */}
                        <div className="table-responsive">
                          <table className="table table-hover">
                            <thead>
                              <tr>
                                <th>Sl No</th>
                                <th>Name</th>
                                <th>Created At</th>
                              </tr>
                            </thead>
                            <tbody>
                              {blogs.map((blog, index) => (
                                <tr key={blog.id} className="">
                                  <td>{index + 1}</td> {/* Serial Number */}
                                  <td>{blog.name}</td>
                                  <td>
                                    {new Date(
                                      blog.createdAt
                                    ).toLocaleDateString()}
                                  </td>{" "}
                                  {/* Format the created date */}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      {/*end::Card footer*/}
                    </div>
                    {/*end::Card*/}
                  </div>
                  <div className="col-lg-6 mt-5  ">
                    <div className="container mt-5">
                      <h2 className="text-center mt-5">
                        Recent Activity Logins
                      </h2>
                      <hr />

                      <div className="table-responsive ">
                        <table
                          className="table table-hover"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <thead className="bg-success text-light" id="white">
                            <tr id="white">
                              <th id="white">Userid</th>
                              <th id="white">Username</th>
                              <th id="white">Login Time</th>
                              <th id="white">Logout Time</th>
                              <th id="white">Total Duration (minutes)</th>
                              <th id="white">Ip Address</th>
                              <th id="white">Device</th>
                            </tr>
                          </thead>
                          <tbody>
                            {logins.length > 0 ? (
                              logins.map((login, index) => {
                                const logout = logouts.find(
                                  (log) =>
                                    log.user_id === login.user_id &&
                                    log.id > login.id
                                );
                                const loginTime = login.timestamp;
                                const logoutTime = logout
                                  ? logout.timestamp
                                  : "-";
                                const duration = logout
                                  ? moment(logout.timestamp).diff(
                                    moment(login.timestamp),
                                    "minutes"
                                  )
                                  : "N/A";

                                return (
                                  <tr key={index}>
                                    <td>{login.user_id}</td>
                                    <td>{login.users_name}</td>
                                    <td>{loginTime}</td>
                                    <td>{logoutTime}</td>
                                    <td>{duration} minutes</td>
                                    <td>
                                      {login.ip_address || "Unknown"}
                                    </td>{" "}
                                    <td>{login.device || "Unknown Device"}</td>{" "}
                                    {/* Device Name */}
                                    {/* Display IP Address */}
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="5" className="text-center">
                                  No recent activity found.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>


                    </div>
                  </div>
                </div>
              </section>


              {/* Row for PieChart and Table */}
              <div className="student p-5 " id="cardss">
                <div className="col-md-12 mt-5 ms-2" id="cardss">
                  <div className="card-box" id="boc">
                    <div className="card-head" id="head">
                      <header id="hh">Recent Student List</header>
                    </div>
                    <div className="card-body p-4">
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th>Sl No</th>
                              <th>Student Name</th>
                              <th>Course</th>
                              <th>Location</th>
                              <th>Registered Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {students.map((student, index) => (
                              <tr key={`${student.id}-${index}`}>
                                {" "}
                                {/* Using both id and index to ensure uniqueness */}
                                <td>{index + 1}</td>
                                <td>{student.name}</td>
                                <td>{student.course}</td>
                                <td>{student.location}</td>
                                <td>{student.registeredDate}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                {/* PieChart */}

                {/* <div className="col-md-3 mt-1 ms-5 ">
                  <PieChart
                    className="pt-5 "
                    series={[
                      {
                        data: [
                          { id: 0, value: 10, label: "series A" },
                          { id: 1, value: 15, label: "series B" },
                          { id: 2, value: 20, label: "series C" },
                        ],
                      },
                    ]}
                    width={300}
                    height={300} // Adjust the height as necessary
                  />
                </div> */}

                {/* Recent Transactions Table */}


              </div>


            </div>

            {/* <Footer /> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
