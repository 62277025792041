

import React, { useEffect, useState } from 'react'
import { fetchSidebarData, fetchtabledataDisplay } from '../customhook/FetchApi';
import qs, { stringify } from "qs";
import Swal from 'sweetalert2';
import { TilingPattern } from 'jspdf';

const UserPermission = () => {
    const [sidebarData, setSidebarData] = useState([]);
    const [usersRoles, setUsersRoles] = useState([])

    const [usersRolesPermission, setUsersRolesPermission] = useState({
        role_id: "",
        create_group: 0,
        edit_group: 0,
        delete_group: 0,
        create_crud: 0,
    });

    const [formData, setFormData] = useState({

    });


    // Fetch sidebar data that ie all crud table data
    useEffect(() => {
        async function fetchData() {
            const result = await fetchSidebarData();
            setSidebarData(result)
        }
        fetchData();
    }, [])


    useEffect(() => {
        // Iterate over each item in sidebarData
        sidebarData.forEach((data) => {
            // Check if `child` array exists and is non-empty
            if (Array.isArray(data.child) && data.child.length > 0) {
                data.child.forEach((child) => {
                    // Check if `crud` array exists in each child
                    if (Array.isArray(child.crud) && child.crud.length > 0) {
                        child.crud.forEach((crud) => {
                            setFormData((prevData) => ({
                                ...prevData,
                                [crud.id]: {
                                    crud_id: crud.id,
                                    role_id: "",
                                    insert_data: 0,
                                    edit_data: 0,
                                    delete_data: 0,

                                    edit_crud: 0,
                                    delete_crud: 0
                                }
                            }
                            ));
                        });
                    } else {

                        setFormData((prevData) => ({
                            ...prevData,
                            [child.id]: {
                                crud_id: child.id,
                                rol_id: "",
                                insert_data: 0,
                                edit_data: 0,
                                delete_data: 0,
                                edit_crud: 0,
                                delete_crud: 0
                            }
                        }
                        ));

                    }
                });
            }
        });
    }, [sidebarData]);




    useEffect(() => {
        console.log("formData")
        console.log(formData)

    }, [formData])



    function handleCheckboxChange(e, crud_id) {
        const { checked, name } = e.target;
        setFormData((prevData) => {
            let newObj = { ...prevData[crud_id] }

            newObj[name] = checked ? 1 : 0;

            return {
                ...prevData,
                crud_id: newObj

            }
        })
    }

    function handleGroupPermission(e) {
        const { checked, name } = e.target;
        console.log(checked, name)

        setUsersRolesPermission((prevData) => ({
            ...prevData,
            [name]: checked ? 1 : 0
        }))

    }

    useEffect(() => {
        console.log("usersRolesPermission")
        console.log(usersRolesPermission)

    }, [usersRolesPermission])

    // Fetch Roles
    useEffect(() => {
        async function fetchData() {
            let result = await fetchtabledataDisplay("tbl_role");

            delete result['visiblefields']

            setUsersRoles(result)
            console.log(result)
        }
        fetchData();
    }, [])



    async function submitForm(e) {
        e.preventDefault();

        if (usersRolesPermission.role_id == "") {
            Swal.fire({
                Title: "Please select a role",
                text: "Select Role"

            })
            return
        }
        const data = {
            "tbl_permission": Object.values(formData),
            "tbl_role": { ...usersRolesPermission }
        };


        try {
            const response = await fetch("https://admissioninnepal.in/api/insert_permission", {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Authorization": "Bearer 12321",
                },
                body: qs.stringify(data)
            });

            // Check response data
            const responseData = await response.json();
            console.log("responseData", responseData);
        } catch (err) {
            console.log(err);
        }
    }

    function handleSelectInput(e) {
        const { name, value } = e.target;
        console.log(name, value)

        setUsersRolesPermission((prevData) => ({
            ...prevData,
            [name]: value
        }))

    }


    return (
        <div className='container'>

            <div className="row">
                <div className="col">

                    <div className="py-5">
                        <select class="form-select"
                            name='role_id'
                            onChange={handleSelectInput}

                        >
                            <option selected >Select User's Role</option>
                            {typeof usersRoles === 'object' && Object.keys(usersRoles).length != 0 && (
                                Object.entries(usersRoles).map(([key, user]) => (
                                    // console.log(value)
                                    <option key={user.id} value={user.id}>{user.role_name}</option>

                                ))

                            )
                            }
                        </select>
                    </div>

                    {/* group create permission */}

                    <div className="py-5 d-flex justify-content-evenly">

                        <div className=''>
                            <label htmlFor="" >Add Group</label>
                            <input type="checkbox"
                                className="form-check-input ms-3 mt-1"
                                name="create_group"
                                onChange={handleGroupPermission}
                            />
                        </div>

                        <div>
                            <label htmlFor="">Edit Group</label>
                            <input type="checkbox"
                                className="form-check-input ms-3 mt-1"
                                name="edit_group"
                                onChange={handleGroupPermission}
                            />
                        </div>

                        <div>
                            <label htmlFor="">Delete Group</label>

                            <input
                                type="checkbox"
                                className="form-check-input  ms-3 mt-1"
                                name="delete_group"
                                onChange={handleGroupPermission}
                            />
                        </div>

                        <div>
                            <label htmlFor="">Create Crud</label>

                            <input
                                type="checkbox"
                                className="form-check-input  ms-3 mt-1"
                                name="create_crud"
                                onChange={handleGroupPermission}
                            />
                        </div>

                    </div>

                    {/* crud and formdata insert permission */}
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Group Name</th>
                                <th scope="col">Group Name(Child)</th>
                                <th scope="col">Table Name</th>
                                <th scope="col">Insert </th>
                                <th scope="col">Edit </th>
                                <th scope="col">Delete</th>
                                <th scope="col">Edit Crud </th>
                                <th scope="col">Crud Delete</th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                sidebarData.map((sidebarObject, index) => {
                                    // Check if Child and Child.crud exist and is an array
                                    if (Array.isArray(sidebarObject.child) && sidebarObject.child.length > 0) {
                                        return (
                                            sidebarObject.child.map((childItem, childIndex) => {
                                                // Check if 'crud' exists in each child item and is an array
                                                if (Array.isArray(childItem.crud) && childItem.crud.length > 0) {
                                                    return (

                                                        // if crud exist then display table data
                                                        childItem.crud.map((crudItem, crudIndex) => (
                                                            <tr key={`${index}-${childIndex}-${crudIndex}`}>

                                                                <td>{sidebarObject.group_name}</td>
                                                                <td>{childItem.group_name}</td>
                                                                <td>{crudItem.table_name}</td>

                                                                <td>
                                                                    <input type="checkbox"
                                                                        class="form-check-input"
                                                                        name="insert_data"
                                                                        onChange={(e) => handleCheckboxChange(e, crudItem.id)}
                                                                    />
                                                                </td>

                                                                <td>
                                                                    <input type="checkbox"
                                                                        class="form-check-input"
                                                                        name="edit_data"
                                                                        onChange={(e) => handleCheckboxChange(e, crudItem.id)}

                                                                    />
                                                                </td>

                                                                <td>
                                                                    <input type="checkbox"
                                                                        class="form-check-input"
                                                                        name='delete_data'
                                                                        onChange={(e) => handleCheckboxChange(e, crudItem.id)}

                                                                    />
                                                                </td>

                                                                <td>
                                                                    <input type="checkbox"
                                                                        class="form-check-input"
                                                                        name='crud_edit'
                                                                        onChange={(e) => handleCheckboxChange(e, crudItem.id)}

                                                                    />
                                                                </td>

                                                                <td>
                                                                    <input type="checkbox"
                                                                        class="form-check-input"
                                                                        name='crud_delete'
                                                                        onChange={(e) => handleCheckboxChange(e, crudItem.id)}

                                                                    />
                                                                </td>
                                                            </tr>
                                                        ))
                                                    );
                                                }
                                                else {
                                                    // If 'crud' does not exist or is not an array, display only child group_name
                                                    return (
                                                        <tr key={`${index}-${childIndex}`}>


                                                            <td>{sidebarObject.group_name}</td>
                                                            <td>{childItem.group_name}</td>
                                                            <td>{childItem.table_name}</td>

                                                            {/* td for insert edit delete */}
                                                            <td>
                                                                <input type="checkbox"
                                                                    class="form-check-input"
                                                                    name="insert"
                                                                    onChange={handleCheckboxChange}

                                                                />
                                                            </td>

                                                            <td>
                                                                <input type="checkbox"
                                                                    class="form-check-input"
                                                                    name="edit"
                                                                    onChange={handleCheckboxChange}

                                                                />
                                                            </td>

                                                            <td>
                                                                <input type="checkbox"
                                                                    class="form-check-input"
                                                                    name='delete'
                                                                    onChange={handleCheckboxChange}

                                                                />
                                                            </td>

                                                            <td>
                                                                <input type="checkbox"
                                                                    class="form-check-input"
                                                                    name='crud_edit'
                                                                    onChange={handleCheckboxChange}

                                                                />
                                                            </td>

                                                            <td>
                                                                <input type="checkbox"
                                                                    class="form-check-input"
                                                                    name='crud_delete'
                                                                    onChange={handleCheckboxChange}

                                                                />
                                                            </td>

                                                        </tr>
                                                    );
                                                }
                                            })
                                        );
                                    } else {

                                        console.log("Crud not created yet")
                                        // If Child or Child.crud does not exist, display only group_name
                                        // return (
                                        //     <tr key={index}>
                                        //         <td>{sidebarObject.group_name}</td>
                                        //     </tr>
                                        // );
                                    }
                                })
                            }





                        </tbody>
                    </table>

                    {/* button to submit formdata */}

                    <button className="btn btn-primary" onClick={submitForm}>Submit</button>
                </div>
            </div>
        </div>
    )
}

export default UserPermission