

import React from 'react'

const ImageBackgroundColorChange = () => {
    return (
        <div>


            <div class="card my_card" style={{ width: "18rem" }}>
                <img src="./img/website_dev-removebg-preview.png" alt="" className='img-fluid' style={{ height: "300px", width: "300px" }} />

                <div class="card-body">
                    <h5 class="card-title">Card title</h5>

                </div>
            </div>


        </div >
    )
}

export default ImageBackgroundColorChange